import ApiService from './Api';
import PrecipitationModel from '../models/Precipitation';

class Precipitation {
  getLastUpdate(
  ): Promise<{lastUpdate: string}> {
    return ApiService.get(`/precipitations/lastUpdate`);
  }

  getByProvince(id: string): Promise<PrecipitationModel[]> {
    return ApiService.get(`/precipitations/${id}`);
  }

  getByProvinceBetween(id: string, start: string, end: string): Promise<PrecipitationModel[]> {
    return ApiService.get(`/precipitations/${id}/between?start=${start}&end=${end}`);
  }
}

export default new Precipitation();
