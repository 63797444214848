import { useCallback, useEffect, useState } from "react";
import Page from "../components/Page";
import PrecipitationChart from "../components/PrecipitationChart";
import Precipitation from "../models/Precipitation";
import Province from "../models/Province";
import ProvinceService from '../services/Province';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import moment from "moment";
import {
  makeStyles,
  createStyles,
  Paper,
  FormControl,
  Select,
  MenuItem,
  Input,
  InputLabel,
  Button,
  FormHelperText
} from "@material-ui/core";
import CustomTable from "../components/CustomTable";
import { DatePicker } from "@material-ui/pickers";

const headersTriggers = [
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'value', title: 'Precipitation', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
  { index: 'trigger', title: 'Trigger' }
];

interface ContentTriggers {
  id: number,
  date: string,
  value: number,
  trigger: string,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2)
    },
    top_container: {
      padding: theme.spacing(2, 0),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    selector_container: {
      display: 'flex',
      flexDirection: 'column',
      '& > div:not(:first-child)': {
        paddingTop: theme.spacing(2)
      }
    },
    bottom_container: {
      height: '40vh'
    },
  }),
);

export default function Historical() {
  const classes = useStyles();
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Province | undefined>(undefined);
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | null>(moment().add(-3, 'month'));
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(moment());
  const [selectedDateErrorMsg, setSelectedDateErrorMsg] = useState<string>('');
  const [refresh, setRefresh] = useState<boolean>(false);

  const [chartPrecipitation, setChartPrecipitation] = useState<Precipitation[] | undefined>(undefined);
  const [chartProvinceTriggers, setChartProvinceTriggers] = useState<{ red_trigger: number, orange_trigger: number, yellow_trigger: number } | undefined>(undefined);
  const [chartStartDate, setChartStartDate] = useState<moment.Moment>(moment().add(-3, 'month'));
  const [chartEndDate, setChartEndDate] = useState<moment.Moment>(moment());

  const [computedTriggers, setComputedTriggers] = useState<ContentTriggers[]>([]);
  const [triggersToShow, setTriggersToShow] = useState<ContentTriggers[]>([]);
  const [triggersPageNb, setTriggersPageNb] = useState<number>(0);
  const [triggersPageSize, setTriggersPageSize] = useState<number>(5);

  useEffect(() => {
    ProvinceService.getAllProvinces()
      .then(provs => {
        setProvinces(provs);
        setSelectedProvince(provs[0]);

        setSelectedDateErrorMsg('');
        setChartProvinceTriggers({
          red_trigger: provs[0].red_trigger,
          orange_trigger: provs[0].orange_trigger,
          yellow_trigger: provs[0].yellow_trigger,
        });
        PrecipitationService.getByProvinceBetween(
          provs[0].id.toString(),
          moment().add(-3, 'month').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        )
          .then(pres => {
            setChartPrecipitation(pres);
            if (pres != undefined) {
              const triggers: ContentTriggers[] = pres
                .filter(pre => Number(pre.value) >= Number(provs[0].yellow_trigger))
                .map(pre => ({
                  id: pre.id,
                  date: pre.date,
                  value: pre.value,
                  trigger: Number(pre.value) >= Number(provs[0].red_trigger)
                    ? 'red_trigger' : Number(pre.value) >= Number(provs[0].orange_trigger)
                      ? 'orange_trigger' : 'yellow_trigger'
                }));
              setTriggersPageNb(0);
              setComputedTriggers(triggers);
            }
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(err => {
        console.log(err);
      })
  }, []);

  useEffect(() => {
    if (selectedProvince && selectedStartDate && selectedEndDate) {
      if (selectedStartDate > selectedEndDate) {
        setSelectedDateErrorMsg('From date should be older than To date.');
      } else {
        setSelectedDateErrorMsg('');
        setChartStartDate(selectedStartDate);
        setChartEndDate(selectedEndDate);
        setChartProvinceTriggers({
          red_trigger: selectedProvince.red_trigger,
          orange_trigger: selectedProvince.orange_trigger,
          yellow_trigger: selectedProvince.yellow_trigger,
        });
        PrecipitationService.getByProvinceBetween(
          selectedProvince.id.toString(),
          selectedStartDate.format('YYYY-MM-DD'),
          selectedEndDate.format('YYYY-MM-DD')
        )
          .then(pres => {
            setChartPrecipitation(pres);
            if (pres != undefined) {
              const triggers: ContentTriggers[] = pres
                .filter(pre => Number(pre.value) >= Number(selectedProvince.yellow_trigger))
                .map(pre => ({
                  id: pre.id,
                  date: pre.date,
                  value: pre.value,
                  trigger: Number(pre.value) >= Number(selectedProvince.red_trigger)
                    ? 'red_trigger' : Number(pre.value) >= Number(selectedProvince.orange_trigger)
                      ? 'orange_trigger' : 'yellow_trigger'
                }));
              setTriggersPageNb(0);
              setComputedTriggers(triggers);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }, [refresh]);

  useEffect(() => {
    setTriggersToShow(computedTriggers.filter((t, index) => index >= triggersPageNb * triggersPageSize && index < (triggersPageNb + 1) * triggersPageSize));
  }, [triggersPageNb, triggersPageSize, computedTriggers])

  const handleChangePageProv = useCallback((pageSize: number, pageNb: number) => {
    setTriggersPageNb(pageNb);
    setTriggersPageSize(pageSize);
  }, []);

  const handleChangeRowsPerPageProv = useCallback((pageSize: number) => {
    setTriggersPageNb(0);
    setTriggersPageSize(pageSize);
  }, []);

  return (
    <Page title="Historical Rainfall">
      <Paper className={classes.paper}>
        <div className={classes.top_container}>
          <div className={classes.selector_container}>
            <div>
              <FormControl>
                <InputLabel color="secondary">Province</InputLabel>
                <Select
                  color="secondary"
                  value={selectedProvince ? selectedProvince.id : ''}
                  autoWidth
                  onChange={(evt: any) => setSelectedProvince(provinces.filter(prov => prov.id === evt.target.value)[0])}
                >
                  {provinces.map(prov => (
                    <MenuItem value={prov.id} key={prov.id}>{prov.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <DatePicker
                label='Date from' color='secondary' format='DD MMM YYYY'
                value={selectedStartDate} onChange={setSelectedStartDate}
              />
            </div>
            <div>
              <DatePicker
                label='Date to' color='secondary' format='DD MMM YYYY'
                value={selectedEndDate} onChange={setSelectedEndDate}
                error={selectedDateErrorMsg != ''} helperText={selectedDateErrorMsg}
              />
            </div>
            <div>
              <Button
                variant='contained'
                color='primary'
                onClick={() => setRefresh(!refresh)}
              >Apply filter</Button>
            </div>
          </div>
          <div>
            <CustomTable
              headers={headersTriggers}
              content={triggersToShow}
              total={computedTriggers.length}
              pageNb={triggersPageNb}
              pageSize={triggersPageSize}
              pageSizeOptions={[5, 10, 15]}
              onChangePage={handleChangePageProv}
              onChangeRowsPerPage={handleChangeRowsPerPageProv}
              onChangeSearch={() => { }}
              disableSearch
            />
          </div>
        </div>
        <div className={classes.bottom_container}>
          {chartProvinceTriggers && chartPrecipitation && (
            <PrecipitationChart
              precipitations={chartPrecipitation}
              red_trigger={chartProvinceTriggers.red_trigger}
              orange_trigger={chartProvinceTriggers.orange_trigger}
              yellow_trigger={chartProvinceTriggers.yellow_trigger}
              min_date={chartStartDate.startOf('day').valueOf()}
              max_date={chartEndDate.startOf('day').valueOf()}
            />
          )}
        </div>
      </Paper>
    </Page>
  );
}