import { debounce, Typography, withStyles } from '@material-ui/core';
import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  TableFooter,
  TablePagination,
  TextField
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import TriggerDot from '../../components/TriggerDot';
import { ReactElement, useCallback, useEffect, useState } from 'react';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #e0e0e0',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: '16px',
      padding: '16px'
    },
    link: {
      textDecoration: 'none'
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2)
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
    top: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(2)
    },
    icon_button: {
      padding: theme.spacing(0.5)
    },
    action_buttons: {
      display: 'flex',
      justifyContent: 'space-evenly'
    },
    delete_button: {
      padding: theme.spacing(0.5),
      color: '#CD3D3D',
      '&:hover': {
        color: '#AD1F1F',
      }
    }
  }),
);

interface CustomTableProps {
  title: string;
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeSearch: Function;
  addFunction: Function;
  editFunction: Function;
  removeFunction: Function;
  additionalActions?: {icon: ReactElement, handler: Function}[];
}

function CustomAdminTable({
  title,
  headers,
  content,
  total,
  pageSize,
  pageNb,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSearch,
  addFunction,
  editFunction,
  removeFunction,
  additionalActions,
}: CustomTableProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  
  const chageSearch = useCallback(
    debounce((name: string) => {
      onChangeSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    chageSearch(search);
  }, [search])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  return (
    <Paper className={classes.paper}>
      <div className={classes.flex}>
        <Typography variant='h5'>{title}</Typography>
        <Button
          startIcon={<AddIcon />}
          variant='contained' color='secondary'
          onClick={() => { addFunction() }}
        >
          Add
        </Button>
      </div>
      <div className={classes.top}>
        <TextField
          size="small"
          label="Search"
          variant="outlined"
          color="secondary"
          value={search}
          onChange={handleChangeSearch}
        />
      </div>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                if (headers.filter(h => h.subHeaders !== undefined).length > 0) {
                  return (
                    <StyledTableCell
                      key={header.index}
                      align="center"
                      rowSpan={header.subHeaders !== undefined ? 1 : 2}
                      colSpan={header.subHeaders !== undefined ? header.subHeaders.length : 1}
                    >
                      {header.title}
                    </StyledTableCell>
                  )
                } else {
                  return (<StyledTableCell key={header.index} align="center">{header.title}</StyledTableCell>)
                }
              })}
              {headers.filter(h => h.subHeaders !== undefined).length > 0 && (
                <StyledTableCell align="center" rowSpan={2}>Action</StyledTableCell>
              )}
              {headers.filter(h => h.subHeaders !== undefined).length === 0 && (
                <StyledTableCell align="center">Action</StyledTableCell>
              )}
            </TableRow>
            {headers.filter(h => h.subHeaders !== undefined).length > 0 && (
              <TableRow>
                {headers.filter(h => h.subHeaders !== undefined).map((header) => (
                  header.subHeaders.map((sh: { index: string, title: string }) => {
                    switch (sh.index) {
                      case 'yellow_trigger':
                        return (
                          <StyledTableCell key={sh.index} align="center"><TriggerDot level={0} />{sh.title}</StyledTableCell>
                        )
                      case 'orange_trigger':
                        return (
                          <StyledTableCell key={sh.index} align="center"><TriggerDot level={1} />{sh.title}</StyledTableCell>
                        )
                      case 'red_trigger':
                        return (
                          <StyledTableCell key={sh.index} align="center"><TriggerDot level={2} />{sh.title}</StyledTableCell>
                        )
                      default:
                        return (
                          <StyledTableCell key={sh.index} align="center">{sh.title}</StyledTableCell>
                        )
                    }
                  })))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {content.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header) => {
                  if (header.subHeaders !== undefined) {
                    return header.subHeaders.map((sh: { index: string, title: string, transformer: Function }) => {
                      if (sh.transformer) {
                        return (
                          <TableCell key={sh.index} align="center">{sh.transformer(row[header.index][sh.index])}</TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={sh.index} align="center">{row[header.index][sh.index]}</TableCell>
                        )
                      }
                    })
                  } else {
                    if (header.transformer) {
                      return (
                        <TableCell key={header.index} align="center">{header.transformer(row[header.index])}</TableCell>
                      )
                    } else {
                      return (
                        <TableCell key={header.index} align="center">{row[header.index]}</TableCell>
                      )
                    }
                  }
                })}
                <TableCell align="center">
                  <div className={classes.action_buttons}>
                    <IconButton className={classes.icon_button} color="secondary" aria-label="edit" onClick={() => { editFunction(row) }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton className={classes.delete_button} aria-label="delete" onClick={() => { removeFunction(row.id) }}>
                      <DeleteIcon />
                    </IconButton>
                    <>
                      { additionalActions && additionalActions.map((action, index) => (
                        <IconButton 
                          key={index}
                          className={classes.icon_button}
                          onClick={() => { action.handler(row) }}
                        >
                          { action.icon }
                        </IconButton>
                      ))}
                    </>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={pageNb}
                onChangePage={handleChangePage}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <div>

      </div>
    </Paper>
  );
}

export default CustomAdminTable;