import { ReactElement } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import Drawer from './Drawer';
import UserTag from './UserTag';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      display: 'flex',
      height: '100%',
      backgroundColor: theme.palette.background.default
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      height: '100%',
    },
    userTag: {
      position: 'absolute',
      top: '8px',
      right: '8px',
    }
  }),
);

interface LayoutProps {
  children: ReactElement;
}

function Layout({ children }: LayoutProps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Drawer />
      <div className={classes.userTag}>
        <UserTag />
      </div>
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
}

export default Layout;