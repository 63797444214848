import { Color } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { MultiPolygon } from 'geojson';
import L, { LatLng, Map } from 'leaflet';
import { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import { Polygon, Popup, Tooltip, useMap } from 'react-leaflet';

interface Props {
  id: string,
  multiPolygon: MultiPolygon,
  color: Color,
  popup: ReactElement,
  tooltip: string,
  isFirst: boolean
}

function InteractiveMultiPolygon({ id, multiPolygon, color, popup, tooltip, isFirst }: Props) {
  const [colorShade, setColorShade] = useState<keyof typeof grey>(500);
  const [firstTimeTrigger, setFirstTimeTrigger] = useState(true);

  return (
    <Polygon
      className={id}
      pathOptions={{ color: color[colorShade] }}
      positions={multiPolygon.coordinates.map((p) => p.map(c => c.map((v) => new LatLng(v[0], v[1]))))}
      eventHandlers={{
        mouseover: () => {
          setColorShade(700);
        },
        mouseout: () => {
          setColorShade(500);
        },
        popupclose: () => {
          setFirstTimeTrigger(false);
        }
      }}
      ref={(ref) => {
        if (ref && isFirst && firstTimeTrigger) {
          ref.openPopup();
        }
      }}
    >
      <Popup maxWidth={600} minWidth={600} maxHeight={600} autoPan>{popup}</Popup>
      <Tooltip>{tooltip}</Tooltip>
    </Polygon>
  );
}

export default InteractiveMultiPolygon;
