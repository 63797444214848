import { Button, createStyles, makeStyles, Paper, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PendingEventsTable from '../components/admin/PendingEventsTable';
import Page from '../components/Page';
import PrecipitationChart from '../components/PrecipitationChart';
import TriggerDot from '../components/TriggerDot';
import EventModel from '../models/EventsView';
import PrecipitationModel from '../models/Precipitation';
import EventService from '../services/EventsView';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';

const headers = [
  { index: 'province', title: 'Province' },
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'value', title: 'Precipitation', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
  { index: null, title: 'Trigger', special: 'triggerSelection' },
  { index: null, title: 'Diff', special: 'diff', transformer: (e: EventModel) => UtilsService.computePrecipitationDiff(e.value, e.yellow_trigger) },
  { index: null, title: 'Accepted', special: 'accepted' },
  { index: 'acceptedAt', title: 'Accepted date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'acceptedBy', title: 'Accepted by', transformer: (u: string) => u ? u : '-' },
  { index: null, title: 'Action', special: 'save' },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
    },
    title: {
      fontWeight: 'bold'
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%'
    },
    flex_col: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      justifyContent: 'space-evenly'
    },
    equation: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '200px'
    },
    equation_fraction: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& > *': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& *:first-child': {
        borderBottom: '1px solid'
      },
    },
  }),
);

function PendingEvents() {
  const classes = useStyles();
  const [events, setEvents] = useState<EventModel[]>([]);
  const [pageSize, setPageSize] = useState<number>(5);
  const [pageNb, setPageNb] = useState<number>(0);
  const [provinceFilter, setProvinceFilter] = useState<string>('');
  const [totalEvents, setTotalEvents] = useState<number>(0);
  const [selectedEvent, setSelectedEvent] = useState<EventModel>();
  const [precipitations, setPrecipitations] = useState<PrecipitationModel[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);

  useEffect(() => {
    EventService.getAllEvents(pageSize, pageNb, provinceFilter).then(res => {
      setEvents(res[0]);
      setTotalEvents(res[1]);
    }).catch(console.log);
  }, [pageSize, pageNb, provinceFilter, refresh]);

  useEffect(() => {
    if (selectedEvent) {
      PrecipitationService.getByProvinceBetween(
        selectedEvent.provinceId.toString(),
        UtilsService.getXdaysBefore(7, selectedEvent.date),
        UtilsService.getXdaysAfter(3, selectedEvent.date)
      ).then(setPrecipitations).catch(console.log);
    }
  }, [selectedEvent]);

  const handleChangePage = (pageSize: number, pageNb: number) => {
    setPageNb(pageNb);
    setPageSize(pageSize);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageNb(0);
    setPageSize(pageSize);
  };

  const handleChangeSelected = (rowNb: number) => {
    setSelectedEvent(events[rowNb]);
  };

  const handleChangeSearch = (filter: string) => {
    setProvinceFilter(filter);
  };

  const handleSaveEvent = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      <Page title={`Pending Events`}>
        <PendingEventsTable
          headers={headers}
          content={events}
          total={totalEvents}
          pageNb={pageNb}
          pageSize={pageSize}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSearch={handleChangeSearch}
          onChangeSelected={handleChangeSelected}
          onSave={handleSaveEvent}
        />
      </Page>
      {selectedEvent && (
        <Paper className={classes.paper}>
          <Typography variant='h6' className={classes.title}>
            Province triggers - {selectedEvent.province}
          </Typography>
          <div className={classes.flex_row}>
            <div className={classes.flex_col}>
              <div><b>Date :</b> {UtilsService.formatDate(selectedEvent.date)}</div>
              <div><b>Precipitation :</b> {UtilsService.formatPrecipitation(selectedEvent.value)}</div>
              <div className={classes.flex_row}>
                <div className={classes.flex_col}>
                  <div><b>Triggers</b></div>
                  <div><TriggerDot level={0} /> {UtilsService.formatPrecipitation(selectedEvent.yellow_trigger)}</div>
                  <div><TriggerDot level={1} /> {UtilsService.formatPrecipitation(selectedEvent.orange_trigger)}</div>
                  <div><TriggerDot level={2} /> {UtilsService.formatPrecipitation(selectedEvent.red_trigger)}</div>
                </div>
                <div className={classes.flex_col}>
                  <div><b>Diff</b></div>
                  <div>{UtilsService.computePrecipitationDiff(selectedEvent.value, selectedEvent.yellow_trigger)}</div>
                  <div>{UtilsService.computePrecipitationDiff(selectedEvent.value, selectedEvent.orange_trigger)}</div>
                  <div>{UtilsService.computePrecipitationDiff(selectedEvent.value, selectedEvent.red_trigger)}</div>
                </div>
              </div>
              <div className={classes.equation}>
                <div><b>Diff</b> = </div>
                <div className={classes.equation_fraction}>
                  <div>value - trigger</div>
                  <div>value</div>
                </div>
                <div> x 100%</div>
              </div>
            </div>
            <div className={classes.flex_col}>
              <PrecipitationChart
                yellow_trigger={selectedEvent.yellow_trigger}
                orange_trigger={selectedEvent.orange_trigger}
                red_trigger={selectedEvent.red_trigger}
                precipitations={precipitations}
              />
            </div>
          </div>
        </Paper>
      )}
    </>
  );
}

export default PendingEvents;