import { MirrorSyncStatus } from '../models/MirrorSyncStatus';
import ApiService from './Api';

class Admin {

  getMirrorSyncStatus(): Promise<MirrorSyncStatus> {
    return ApiService.get('/admin/mirror/status');
  }

  runMirroSync(): Promise<void> {
    return ApiService.post('/admin/mirror', {});
  }
  
}

export default new Admin();
