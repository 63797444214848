import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import useUser from '../hooks/user';
import {
  createStyles,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Role } from '../models/User';
import InsureeService from '../services/Insuree';
import BenefitService from '../services/Benefit';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import InsureeModel from '../models/Insuree';
import moment from 'moment';

const headers = [
  { index: 'province', title: 'Province' },
  { index: 'coverage', title: 'Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'date', title: 'Date of Trigger', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'value', title: 'Value', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'benefit', title: 'Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
]

interface Content {
  id: number,
  province: string,
  coverage: number,
  date: string,
  value: number,
  trigger: string,
  benefit: number
}

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex'
    },
    select: {
      margin: '16px'
    }
  }),
);

function TriggeredEvents() {
  const classes = useStyles();
  const { user } = useUser();
  const [benefits, setBenefits] = useState<Content[]>([]);
  const [pageNb, setPageNb] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);

  const [lastUpdate, setLastUpdate] = useState<string>('-');
  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [insurees, setInsurees] = useState<InsureeModel[]>([]);
  const [insuree, setInsuree] = useState<number>(0);

  const initInsureeView = () => {
    BenefitService.getMyBenefits(pageSize, pageNb).then(res => {
      const contents = res[0].map(b => {
        const content: Content = {
          id: b.id,
          province: b.cover.province.name,
          coverage: parseFloat(b.cover.amount),
          date: moment(b.date).format('DD MMM YYYY'),
          value: b.precipitation.value,
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      setBenefits(contents);
      setTotal(res[1]);
    },
      err => { console.log(err) }
    );
    BenefitService.getMyTotalBenefitsValue().then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
  }

  const initNoneInsureeView = (id: number) => {
    BenefitService.getBenefitsOfInsuree(id.toString(), pageSize, pageNb).then(res => {
      const contents = res[0].map(b => {
        const content: Content = {
          id: b.id,
          province: b.cover.province.name,
          coverage: parseFloat(b.cover.amount),
          date: b.date,
          value: b.precipitation.value,
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      setBenefits(contents);
      setTotal(res[1]);
    },
      err => { console.log(err) }
    );
    BenefitService.getTotalBenefitsValueOfInsuree(id.toString()).then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
  }

  useEffect(() => {
    if (user.role === Role.Insuree) {
      initInsureeView();
    } else {
      InsureeService.getAllInsurees(true, true)
        .then(res => {
          setInsurees(res);
          setInsuree(res[0].id);
          initNoneInsureeView(res[0].id);
        },
          err => { console.log(err) });
    }
    PrecipitationService.getLastUpdate()
      .then(res => {
        setLastUpdate(res.lastUpdate);
      },
        err => { console.log(err) });
  }, [pageSize, pageNb]);

  useEffect(() => {
    if (user.role !== Role.Insuree) {
      initNoneInsureeView(insuree);
    }
  }, [insuree])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setInsuree(event.target.value as number);
  };

  const handleChangePage = (pageSize: number, pageNb: number) => {
    setPageNb(pageNb);
    setPageSize(pageSize);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageNb(0);
    setPageSize(pageSize);
  };

  return (
    <Page title="Triggered Events">
      <>
        {user.role !== Role.Insuree &&
          <FormControl className={classes.select}>
            <InputLabel id="demo-simple-select-label" color="secondary">Cooperatives</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              color="secondary"
              value={insurees.length > 0 ? insuree : ''}
              onChange={handleChange}
              autoWidth
            >
              {insurees.map((i, index) => (
                <MenuItem key={index} value={i.id} selected={insuree === i.id}>{i.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      </>
      <div className={classes.flex}>
        <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
        <SingleDataCard title={'Last Update'} content={UtilsService.formatDate(lastUpdate)} />
      </div>
      <CustomTable
        headers={headers}
        content={benefits}
        total={total}
        pageNb={pageNb}
        pageSize={pageSize}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        disableSearch={true}
        onChangeSearch={() => {}}
      />
    </Page>
  );
}

export default TriggeredEvents;