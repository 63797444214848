import ActivityModel from '../models/Activity';
import ApiService from './Api';

class Activity {

  get(pageSize: number, pageNb: number): Promise<[ActivityModel[], number]> {
    return ApiService.get(`/activity?pageSize=${pageSize}&pageNb=${pageNb}`);
  }
  
}

export default new Activity();
