import {
  makeStyles,
  createStyles,
  Theme,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import ProvinceService from '../../services/Province';
import InsureeService from '../../services/Insuree';
import UtilsService from '../../services/Utils';
import ProvinceModel from '../../models/Province';
import payoutConfig from '../../configurations/payouts.json';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex_column: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '75vh',
      overflow: 'auto'
    },
    flex_column_space: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(0, 4, 4, 4),
    },
    totalCoverage: {
      alignSelf: 'center',
      margin: theme.spacing(4, 0, 0),
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1, 2)
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    provinces: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      margin: theme.spacing(4),
      backgroundColor: theme.palette.background.default
    },
    submit: {
      width: theme.spacing(16),
      alignSelf: 'center'
    }
  }),
);

interface Props {
  handleClose: Function;
}

function AddCooperative({ handleClose }: Props) {
  const classes = useStyles();
  const [provinces, setProvinces] = useState<ProvinceModel[]>([]);
  const [totalCoverage, setTotalCoverage] = useState<string>('0$');
  const [name, setName] = useState<string>('');
  const [covers, setCovers] = useState<{ province: number, amount: number }[]>([]);

  useEffect(() => {
    ProvinceService.getAllProvinces().then(
      res => { setProvinces(res) },
      err => { console.log(err) }
    );
  }, []);

  useEffect(() => {
    setTotalCoverage(UtilsService.formatCurrency(covers.reduce((acc: number, curr) => acc + curr.amount, 0)));
  }, [covers]);

  const handleChangeCoopName = (value: string) => {
    setName(value);
  }

  const handleChangeProvinceCoverage = (province: number, amount: number, enabled: boolean) => {
    const tmp: { province: number, amount: number }[] = [];
    tmp.push(...covers);
    if (enabled) {
      const c = tmp.find(c => c.province === province);
      if (c !== undefined) {
        c.amount = amount;
      } else {
        tmp.push({ province, amount });
      }
    } else {
      const index = tmp.findIndex(c => c.province === province);
      if (index !== -1) {
        tmp.splice(index, 1);
      }
    }
    setCovers(tmp);
  }

  const submit = () => {
    InsureeService.createInsuree({
      name,
      covers: covers.map(c => {
        return {
          province: c.province,
          amount: c.amount,
          from: new Date(),
          to: moment().add(1, 'year').toDate(),
          red_payout: payoutConfig.red_payout,
          orange_payout: payoutConfig.orange_payout,
          yellow_payout: payoutConfig.yellow_payout,
        }
      })
    }).then(
      res => { handleClose() },
      err => { console.log(err) }
    );
  }

  return (
    <div className={classes.flex_column}>
      <Typography variant='h5'>Add Cooperative</Typography>
      <div className={classes.flex_column_space}>
        <TextField label='Cooperative Name' onChange={(e) => { handleChangeCoopName(e.target.value) }} />
      </div>
      <Button variant='contained' color='secondary'
        className={classes.submit} onClick={submit}
      >
        Submit
      </Button>
    </div>
  );
}

export default AddCooperative;