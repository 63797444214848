import {
  makeStyles,
  createStyles,
  Theme,
  Typography,
  TextField,
  Button,
  Box
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex_column: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      '& > *:not(:first-of-type)': {
        marginTop: theme.spacing(2)
      }
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-evenly',
    },
    flex_row_space_between: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    provinces_options_field: {
      width: '100%',
    },
    submit: {
      width: theme.spacing(16),
      alignSelf: 'center'
    },
    delete: {
      width: theme.spacing(16),
      alignSelf: 'center',
      backgroundColor: '#CD3D3D',
      color: theme.palette.background.default,
      '&:hover': {
        backgroundColor: '#AD1F1F',
      }
    }
  }),
);

interface Props {
  provinceName: string;
  coverId: number;
  handleUpdate: (provinceId: number, amount: number, from: moment.Moment, to: moment.Moment) => any;
  handleDelete: (coverId: number) => any;
  amount: number;
  from: moment.Moment;
  to: moment.Moment;
}

function ProvinceCoverageForm({ provinceName, coverId, handleUpdate, handleDelete, amount, from: _from, to: _to }: Props) {
  const classes = useStyles();
  const [coverage, setCoverage] = useState<number>(amount);
  const [from, setFrom] = useState<moment.Moment | null>(_from);
  const [to, setTo] = useState<moment.Moment | null>(_to);

  const handleChangeProvinceCoverage = (value: string) => {
    setCoverage(parseFloat(value));
  }

  const _handleUpdate = () => {
    handleUpdate(coverId, coverage, from ? from : moment(), to ? to : moment().add(1, 'year'));
  }

  const _handleDelete = () => {
    handleDelete(coverId);
  }

  return (
    <Box className={classes.flex_column}>
      <Typography style={{ fontWeight: 'bold' }}>{provinceName}</Typography>
      <Box className={classes.flex_row}>
        <TextField label='Coverage'
          defaultValue={coverage}
          className={classes.provinces_options_field}
          type='Number'
          onChange={(e) => { handleChangeProvinceCoverage(e.target.value) }}
        />
      </Box>
      <Box className={classes.flex_row_space_between}>
        <DatePicker
          label='Start of coverage' color='secondary' format='DD MMM YYYY'
          value={from} onChange={setFrom}
        />
        <DatePicker
          label='End of coverage' color='secondary' format='DD MMM YYYY'
          value={to} onChange={setTo}
        />
      </Box>
      <Box className={classes.flex_row}>
        <Button variant='contained' color='secondary'
          className={classes.submit} onClick={_handleUpdate}
        >
          Update
        </Button>
        <Button variant='contained'
          className={classes.delete} onClick={_handleDelete}
        >
          Delete
        </Button>
      </Box>
    </Box>
  );
}

export default ProvinceCoverageForm;