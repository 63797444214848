import ApiService from './Api';
import UserModel, { Role } from '../models/User';

class User {

  me():Promise<UserModel> {
    return ApiService.get('/users/me');
  }

  changePassword(currentPassword: string, newPassword: string): Promise<void> {
    return ApiService.post(
      '/users/me/password',
      { currentPassword, newPassword }
    );
  }

  renewPassword(token: string, newPassword: string): Promise<void> {
    return ApiService.post(
      '/users/password/renew',
      { token, newPassword }
    );
  }

  getAllUsers(nbResultsPerPage: number, page: number): Promise<{ count: number, values: UserModel[]}> {
    return ApiService
      .get(`/users?pageNb=${page}&pageSize=${nbResultsPerPage}`)
      .then(([values, count]) => ({ count, values }));
  }

  changeUserStatus(userId: number, active: boolean): Promise<void> {
    return ApiService.post(
      `/users/${userId}/${active ? 'enable' : 'disable'}`,
      {}
    );
  }

  changeUserPassword(userId: number, newPassword: string): Promise<void> {
    return ApiService.post(
      `/users/${userId}/password`,
      { newPassword }
    );
  }

  requestRenewUserPassword(email: string): Promise<void> {
    return ApiService.post(
      `/users/request/password/renew`,
      { email }
    );
  }

  create(email: string, name: string, role: Role, insureeId: number | null): Promise<void> {
    return ApiService.post(
      '/users',
      {
        email,
        name,
        role,
        insureeId: role === Role.Insuree ? insureeId : null,
      }
    );
  }
  
}

export default new User();
