import { useEffect } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import ReactPiwik from 'react-piwik';

import useAuth from '../hooks/auth';
import Layout from './Layout';
import OverviewPage from '../pages/Overview';
import AdminPage from '../pages/Admin';
import AdminLogsPage from '../pages/AdminLogs';
import Activity from '../pages/Activity';
import ContactPage from '../pages/Contact';
import RecommendationsPage from '../pages/Recommendation';
import DashboardPage from '../pages/Dashboard';
import ProductPage from '../pages/Product';
import LoginPage from '../pages/Login';
import OpenIDLoginPage from '../pages/OpenIDLogin';
import RenewPasswordPage from '../pages/RenewPassword';
import ForgotPasswordPage from '../pages/ForgotPassword';
import UserProfilePage from '../pages/UserProfile';
import TriggeredEventsPage from '../pages/TriggeredEvents';
import PendingEventsPage from '../pages/PendingEvents';
import Historical from '../pages/Historical';
import DashboardProvincePage from '../pages/DashboardProvince';

const matomoEnabled = !!process.env.REACT_APP_MATOMO_HOST;
const matomo =
  matomoEnabled
    ? new ReactPiwik({
      url: process.env.REACT_APP_MATOMO_HOST!,
      siteId: parseInt(process.env.REACT_APP_MATOMO_SITEID!),
    })
    : null;

const Child = ({ isAuthenticated, isAdmin, isSuperUser }: { isAuthenticated: boolean; isAdmin: boolean; isSuperUser: boolean; }) => {
  const history = useHistory();

  useEffect(() => {
    if (matomoEnabled) {
      matomo!.connectToHistory(history);
    }
  }, []);

  return (
    <>
      {!isAuthenticated && (
        <Switch>
          <Route path="/login/:result?">
            <OpenIDLoginPage />
          </Route>
          <Route path="/renewPassword/:token">
            <RenewPasswordPage />
          </Route>
          <Route path="/initPassword/:token">
            <RenewPasswordPage />
          </Route>
          <Route path="/forgotPassword">
            <ForgotPasswordPage />
          </Route>
          <Redirect
            to='/login'
          />
        </Switch>
      )}
      {isAuthenticated && (
        <Layout>
          <Switch>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/recommendations">
              <RecommendationsPage />
            </Route>
            <Route path="/pendingEvents">
              <PendingEventsPage />
            </Route>
            <Route exact path="/dashboard">
              <DashboardPage />
            </Route>
            <Route exact path="/dashboard/:provinceId">
              <DashboardProvincePage />
            </Route>
            <Route exact path="/dashboard/:provinceId/:insureeId">
              <DashboardProvincePage />
            </Route>
            <Route path="/product">
              <ProductPage />
            </Route>
            <Route path="/triggered-events">
              <TriggeredEventsPage />
            </Route>
            {isAdmin && (
              <Route path="/admin">
                <AdminPage />
              </Route>
            )}
            {isAdmin && (
              <Route path="/adminLogs">
                <AdminLogsPage />
              </Route>
            )}
            {isAdmin && (
              <Route path="/activity">
                <Activity />
              </Route>
            )}
            {(isSuperUser || isAdmin) && (
              <Route path="/overview">
                <OverviewPage />
              </Route>
            )}
            <Route path="/historical-rainfall">
              <Historical />
            </Route>
            <Route path="/me">
              <UserProfilePage />
            </Route>
            {(isSuperUser || isAdmin) && (
              <Redirect
                to='/overview'
              />
            )}
            {!(isSuperUser || isAdmin) && (
              <Redirect
                to='/dashboard'
              />
            )}
          </Switch>
        </Layout>
      )}
    </>
  );
};

const Router = () => {
  const {
    isAuthenticated,
    isSuperUser,
    isAdmin,
  } = useAuth();

  return (
    <BrowserRouter>
      <Child {...{ isAuthenticated, isAdmin, isSuperUser }} />
    </BrowserRouter>
  );
}

export default Router;
