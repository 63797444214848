import ApiService from './Api';
import CoverModel from '../models/Cover';
import CoverCreateModel from '../models/creationModels/Cover';
import CoverUpdateModel from '../models/updateModels/Cover';
import moment from 'moment';

class Cover {

  getCoversOfInsuree(
    id: string,
    withBenefits: boolean,
    withProvinces: boolean
  ):Promise<CoverModel[]> {
    return ApiService.get(`/covers/ofInsuree/${id}?benefits=${withBenefits}&provinces=${withProvinces}`);
  }

  getMyCovers(
    withBenefits: boolean,
    withProvinces: boolean
  ):Promise<CoverModel[]> {
    return ApiService.get(`/covers/me?benefits=${withBenefits}&provinces=${withProvinces}`);
  }

  getTotalCoverageOfInsuree(
    id: string,
    date?: moment.Moment
  ): Promise<{sum: number}> {
    return ApiService.get(`/covers/total/ofInsuree/${id}${date ? `?date=${date.format('YYYY-MM-DD')}` : ``}`);
  }

  getMyTotalCoverage(
    date?: moment.Moment
  ): Promise<{sum: number}> {
    return ApiService.get(`/covers/total/me${date ? `?date=${date.format('YYYY-MM-DD')}` : ``}`);
  }

  getTotalCoverageOfInsureeForProvince(
    insureeId: string,
    provinceId: string
  ): Promise<{sum: number}> {
    return ApiService.get(`/covers/total/ofInsuree/${insureeId}/${provinceId}`);
  }

  getMyTotalCoverageForProvince(
    provinceId: string
  ): Promise<{sum: number}> {
    return ApiService.get(`/covers/total/me/${provinceId}`);
  }

  createCover(cover: CoverCreateModel): Promise<CoverModel> {
    return ApiService.post(`/covers/create`, cover);
  }

  updateCover(cover: CoverUpdateModel): Promise<CoverModel> {
    return ApiService.post(`/covers/update`, cover);
  }

  deleteCover(coverId: number): Promise<CoverModel> {
    return ApiService.delete(`/covers/delete/${coverId}`);
  }
  
}

export default new Cover();
