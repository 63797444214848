import {
  Typography,
  Box,
} from '@material-ui/core';
import { ReactElement } from 'react';

interface PageProps {
  title: string;
  children: ReactElement | ReactElement[];
}

function Page({ title, children }: PageProps) {
  return (
    <Box style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" component="span" >
        {title}
      </Typography>
      <Box mt={2} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {children}
      </Box>
    </Box>
  );
}

export default Page;
