import { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import UserService from '../services/User';
import _envConfig from '../configurations/env.json';
import { Env } from '../configurations/env';

const useStyles = makeStyles((theme) =>
  createStyles({
    snackbar: {
      backgroundColor: theme.palette.secondary.main,
      textAlign: 'center',
      display: 'block'
    }
  }),
);

interface FormData {
  email: string;
}

function ForgotPassword() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<String | null>(null);
  const { register, formState: { errors } , handleSubmit } = useForm<FormData>();
  const [open, setOpen] = useState(false);

  const env: Env = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev') as Env;
  const envConfig = _envConfig.themes.filter(t => t.envs.includes(env))[0];

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSubmit = ({ email }: FormData) => {
    setError(null);
    setLoading(true);
    UserService
      .requestRenewUserPassword(email)
      .then(() => {
        setLoading(false);
        setOpen(true);
      })
      .catch((e) => {
        setLoading(false);
        if (e.message) {
          setError(e.message);
        } else {
          setError('Unknown error')
        }
      });
  };

  return (
    <Container maxWidth="sm">
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <img
          alt="Rainfall Platform"
          src={envConfig.logo_on_light}
          style={{ width: '50%'}}
        />
        <Box mt={1} style={{ width: '100%'}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email address"
              autoComplete="email"
              variant="outlined"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...register('email', {
                required: 'This field is required',
              })}
            />
            { error !== null && (
              <Box>
                <Typography color="error">
                  { error }
                </Typography>  
              </Box>
            )}
            <Box mt={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Send password renewal
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
                href="/login"
              >
                Back
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
      <Snackbar
        ContentProps={{ className: classes.snackbar }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Request sent, check your emails !"
      />
    </Container>
  );
}

export default ForgotPassword;