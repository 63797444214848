import {
  makeStyles,
  createStyles,
  Paper,
  TextField,
  Button,
  Snackbar
} from '@material-ui/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import Page from '../components/Page';
import MailService from '../services/Mail';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
    },
    flex: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        margin: theme.spacing(1, 0)
      }
    },
    snackbar: {
      backgroundColor: theme.palette.secondary.main,
      textAlign: 'center',
      display: 'block'
    }
  }),
);

interface IFormInput {
  name: string;
  email: string;
  message: string;
}

const schema = Joi.object({
  name: Joi.string().required(),
  email: Joi.string().required(),
  message: Joi.string().required()
});

function Contact() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset
  } = useForm<IFormInput>({
    resolver: joiResolver(schema)
  });

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSubmit = (data: IFormInput) => {
    MailService.sendContact(data.email, data.name, data.message)
      .then(() => {
        reset()
        setOpen(true);
      }).catch((err) => {
        console.log(err);
      });
  };

  return (
    <Page title="Contact">
      <Paper className={classes.paper}>
        Do you need help or have suggestions? Use this form to contact us.
        <form className={classes.flex} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Name"
            variant="outlined"
            color="primary"
            required={true}
            {...register('name')}
          />
          <TextField
            label="Email"
            variant="outlined"
            color="primary"
            required={true}
            {...register('email')}
          />
          <TextField
            label="Message"
            variant="outlined"
            color="primary"
            multiline
            rows={10}
            required={true}
            {...register('message')}
          />
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            Send
          </Button>
        </form>
      </Paper>
      <Snackbar
        ContentProps={{ className: classes.snackbar }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Email sent successfully !"
      />
    </Page>
  );
}

export default Contact;