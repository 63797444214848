import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { useForm, Controller } from 'react-hook-form';

import { Role } from '../../models/User';
import InsureeService from '../../services/Insuree';
import UserService from '../../services/User';
import Insuree from '../../models/Insuree';

interface CreateUserProps {
  onClose: (reload: boolean) => void;
}

interface FormData {
  email: string;
  name: string;
  role: Role;
  insureeId: number;
}

const CreateUser = ({ onClose }: CreateUserProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { register, formState: { errors } , handleSubmit, watch, control } = useForm<FormData>();
  const [insurees, setInsurees] = useState<Insuree[]>([]);

  useEffect(() => {
    setLoading(true);
    InsureeService
      .getAllInsurees(false, false)
      .then((insurees) => {
        setInsurees(insurees);
        setLoading(false);
      });
  }, []);

  const onSubmit = (data: FormData) => {
    setLoading(true);
    setError(null);
    UserService
      .create(
        data.email,
        data.name,
        data.role,
        data.role === Role.Insuree ? data.insureeId : null,
      )
      .then(() => {
        setLoading(false);
        onClose(true);
      })
      .catch((err) => {
        setLoading(false);
        if(err.statusCode === 409)
          setError("An error occurd: this email address already exists");
        else
          setError("An error occurd: cannot create this user");
      })
  };

  return (
    <Dialog
      open
      fullWidth
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create new user</DialogTitle>
        <DialogContent>
          <Box style={{ width: '100%'}}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Email"
              variant="outlined"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...register('email', {
                required: 'This field is required',
              })}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label="Name"
              variant="outlined"
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              {...register('name', {
                required: 'This field is required',
              })}
            />
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>
                Role
              </InputLabel>
              <Controller
                control={control}
                name="role"
                rules={{
                  required: "This field is required",
                  min: { value: 0, message: "This field is required" },
                }}
                render={({ field })=> (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    error={Boolean(errors.role)}
                    label="Role"
                  >
                    <MenuItem value={-1} />
                    <MenuItem value={Role.Admin}>Admin</MenuItem>
                    <MenuItem value={Role.SuperUser}>CLIMBS</MenuItem>
                    <MenuItem value={Role.Insuree}>User</MenuItem>
                  </Select>
                )}
              />
              { errors.role && errors.role.message && (
                <FormHelperText error>
                  {errors.role.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>
                Cooperative
              </InputLabel>
              <Controller
                control={control}
                name="insureeId"
                rules={{
                  required: { value: watch('role') === Role.Insuree, message: "This field is required" },
                  min: { value: 0, message: "This field is required" },
                }}
                render={({ field })=> (
                  <Select
                    onChange={field.onChange}
                    value={field.value}
                    error={Boolean(errors.insureeId)}
                    label="Cooperative"
                    disabled={watch('role') !== Role.Insuree}
                  >
                    <MenuItem value={-1} />
                    { insurees.map((insuree) => (
                      <MenuItem
                        key={insuree.id}
                        value={insuree.id}
                      >
                        { insuree.name }
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              { errors.insureeId && errors.insureeId.message && (
                <FormHelperText error>
                  {errors.insureeId.message}
                </FormHelperText>
              )}
            </FormControl>
            { error !== null && (
              <Box>
                <Typography color="error">
                  { error }
                </Typography>  
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          { loading && (
            <CircularProgress size={30} />
          )}
          <Button onClick={() => onClose(false)} disabled={loading}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="submit"
          >
            Confirm
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateUser;
