import {
  makeStyles,
  createStyles,
  Button,
} from '@material-ui/core';

import ProvinceService from '../../services/Province';
import PrecipitationService from '../../services/Precipitation';
import PrecipitationModel from '../../models/Precipitation';
import PrecipitationChart from '../PrecipitationChart';
import { useEffect, useState } from 'react';
import useUser from '../../hooks/user';
import { Role } from '../../models/User';
import ProvinceModel from '../../models/Province';
import moment from 'moment';

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '300px'
    },
  }),
);

interface Props { provinceId: string, insureeId?: string }

function DashboardProvincePopup({ provinceId, insureeId }: Props) {
  const classes = useStyles();
  const { user } = useUser();
  const [province, setProvince] = useState<ProvinceModel>();
  const [precipitations, setPrecipitations] = useState<PrecipitationModel[]>([]);

  const initInsureeProvinceView = () => {
    ProvinceService.getMyProvince(provinceId).then((res) => {
      setProvince(res);
    },
      err => { console.log(err) }
    );
  };

  const initNoneInsureeProvinceView = () => {
    if (insureeId) {
      ProvinceService.getProvinceOfInsuree(provinceId, insureeId).then((res) => {
        setProvince(res);
      },
        err => { console.log(err) }
      );
    }
  };

  useEffect(() => {
    if (user.role === Role.Insuree) {
      initInsureeProvinceView();
    } else {
      initNoneInsureeProvinceView();
    }
  }, []);

  useEffect(() => {
    PrecipitationService.getByProvince(provinceId).then(res => {
      const startOfCoverage = province ? moment(province.covers.reduce((pre, cur) => moment(pre.from).isSameOrBefore(cur.from) ? pre : cur).from) : null;
      const endOfCoverage = province ? moment(province.covers.reduce((pre, cur) => moment(pre.to).isSameOrAfter(cur.to) ? pre : cur).to) : null;
      setPrecipitations(
        res
          .filter(pre => startOfCoverage ? moment(pre.date).isSameOrAfter(startOfCoverage) : true) // must be after coverage start
          .filter(pre => endOfCoverage ? moment(pre.date).isSameOrBefore(endOfCoverage) : true) // must be before coverage end
          .filter(pre => moment(pre.date).isSameOrBefore(moment())) // must be before current date
          .filter((pre, ind, arr) => moment(pre.date).isSameOrAfter(moment(arr[arr.length - 1].date).startOf('day').add(-3, 'months'))) // must be after last date - 3 months
          .sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1)
      );
    },
      err => { console.log(err) }
    );
  }, [province])

  return (
    <div className={classes.flex}>
      {province && (
        <>
          <PrecipitationChart
            precipitations={precipitations}
            red_trigger={province.red_trigger}
            orange_trigger={province.orange_trigger}
            yellow_trigger={province.yellow_trigger}
            min_date={(precipitations.length > 0 ? moment(precipitations[0].date) : moment()).startOf('day').valueOf()}
            max_date={(precipitations.length > 0 ? moment(precipitations[precipitations.length - 1].date) : moment()).startOf('day').valueOf()}
          />
          <Button
            variant='contained'
            color='secondary'
            href={`/dashboard/${provinceId}${insureeId ? `/${insureeId}` : ``}`}
          >
            More info
          </Button>
        </>
      )}
    </div>
  );
}

export default DashboardProvincePopup;