import { useState } from 'react';
import {
  Paper,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Box,
  TextField,
  Button,
} from '@material-ui/core';
import { useForm } from 'react-hook-form';

import UserService from '../services/User';

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    paper: {
      padding: theme.spacing(3)
    }
  })
);

interface FormData {
  currentPassword: string;
  newPassword: string;
  newPasswordConf: string;
}

const ChangePassword = () => {
  const classes = useStyles();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [loading, setLoading] = useState(false);
  const { register, formState: { errors } , handleSubmit, watch, reset } = useForm<FormData>();

  const onSubmit = (data: FormData) => {
    setLoading(true);
    setError(null);
    UserService
      .changePassword(data.currentPassword, data.newPassword)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        reset();
      })
      .catch(() => {
        setLoading(false);
        setError('An error occured: please check your current password');
      });
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h5">
        Change my password
      </Typography>
      <Box mt={1} style={{ width: '100%'}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Current password"
            variant="outlined"
            type="password"
            error={Boolean(errors.currentPassword)}
            helperText={errors.currentPassword?.message}
            {...register('currentPassword', {
              required: 'This field is required',
            })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="New password"
            variant="outlined"
            type="password"
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword?.message}
            {...register('newPassword', {
              required: 'This field is required',
              minLength: { value: 10, message: 'Password mut be at least 10 characters long' }
            })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="New password confirmation"
            variant="outlined"
            type="password"
            error={Boolean(errors.newPasswordConf)}
            helperText={errors.newPasswordConf?.message}
            {...register('newPasswordConf', {
              required: 'This field is required',
              validate: (value) => value === watch('newPassword') || 'Password and confirmation does not match',
            })}
          />
          { error !== null && (
            <Box>
              <Typography color="error">
                { error }
              </Typography>  
            </Box>
          )}
          { success === true && (
            <Box>
              <Typography color="primary">
                Your password has been updated!
              </Typography>  
            </Box>
          )}
          <Box mt={2}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
            >
              Upate password
            </Button>
          </Box>
        </form>
      </Box>
    </Paper>
  );
};

export default ChangePassword;
