import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  withStyles,
  TablePagination,
  TextField,
  debounce,
  Typography
} from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { useCallback, useEffect, useState } from 'react';
import {
  NavLink as RouterLink,
} from 'react-router-dom';

import TriggerDot from '../components/TriggerDot';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #e0e0e0',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
    },
    link: {
      textDecoration: 'none'
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
    top: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(2)
    },
    totalRow: {
      backgroundColor: theme.palette.background.default,
      '& > *': {
        fontWeight: 'bold'
      }
    },
    callback: {
      cursor: 'pointer',
      color: purple[500],
      '&:hover': {
        color: purple[800]
      }
    },
    emptyData: {
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
      textAlign: 'center'
    }
  }),
);

interface CustomTableProps {
  headers: any[];
  content: any[];
  total?: number;
  pageSize?: number;
  pageNb?: number;
  disablePagination?: boolean;
  onChangePage?: Function;
  onChangeRowsPerPage?: Function;
  onChangeSearch?: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
  title?: string;
}

function CustomTable({
  headers,
  content,
  total = 0,
  pageSize = 0,
  pageNb = 0,
  disablePagination = false,
  onChangePage = () => { },
  onChangeRowsPerPage = () => { },
  onChangeSearch = () => { },
  disableSearch = false,
  pageSizeOptions = [10, 25, 50, 100],
  title
}: CustomTableProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const chageSearch = useCallback(
    debounce((name: string) => {
      onChangeSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    chageSearch(search);
  }, [search])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  return (
    <Paper className={classes.paper}>
      {(!disableSearch || title) && (
        <div className={classes.top}>
          {title && (
            <Typography variant='h5' style={{ marginRight: 'auto' }}>{title}</Typography>
          )}
          {!disableSearch && (
            <TextField
              size="small"
              label="Search"
              variant="outlined"
              color="secondary"
              onChange={handleChangeSearch}
            />
          )}
        </div>
      )}
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => {
                if (headers.filter(h => h.subHeaders !== undefined).length > 0) {
                  return (
                    <StyledTableCell
                      key={header.index}
                      align="center"
                      rowSpan={header.subHeaders !== undefined ? 1 : 2}
                      colSpan={header.subHeaders !== undefined ? header.subHeaders.length : 1}
                    >
                      {header.title}
                    </StyledTableCell>
                  )
                } else {
                  return (<StyledTableCell key={header.index} align="center">{header.title}</StyledTableCell>)
                }
              })}
            </TableRow>
            {headers.filter(h => h.subHeaders !== undefined).length > 0 && (
              <TableRow>
                {headers.filter(h => h.subHeaders !== undefined).map((header) => (
                  header.subHeaders.map((sh: { index: string, title: string }) => {
                    return <StyledTableCell key={sh.index} align="center">{sh.title}</StyledTableCell>
                  })))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {content.map((row, index) => (
              <TableRow key={index} className={row['isTotal'] && classes.totalRow}>
                {headers.map((header) => {
                  switch (row[header.index]) {
                    case 'yellow_trigger':
                      return (
                        <TableCell key={header.index} align="center"><TriggerDot level={0} /></TableCell>
                      )
                    case 'orange_trigger':
                      return (
                        <TableCell key={header.index} align="center"><TriggerDot level={1} /></TableCell>
                      )
                    case 'red_trigger':
                      return (
                        <TableCell key={header.index} align="center"><TriggerDot level={2} /></TableCell>
                      )
                    default:
                      if (header.subHeaders !== undefined) {
                        return header.subHeaders.map((sh: { index: string, title: string, transformer: Function }) => {
                          if (sh.transformer) {
                            return (
                              <TableCell key={sh.index} align="center">{sh.transformer(row[header.index][sh.index])}</TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={sh.index} align="center">{row[header.index][sh.index]}</TableCell>
                            )
                          }
                        })
                      } else if (header.isLink) {
                        return (
                          <TableCell key={header.index} align="center">
                            <RouterLink to={row[header.index].link} className={`${classes.link} tableLink${index}`} id={`tableLink${index}`}>
                              {row[header.index].value}
                            </RouterLink>
                          </TableCell>
                        )
                      } else if (header.isCallback) {
                        return (
                          <TableCell key={header.index} align="center">
                            <a onClick={() => row[header.index].callback()} className={classes.callback}>
                              {row[header.index].value}
                            </a>
                          </TableCell>
                        )
                      } else {
                        if (header.transformer) {
                          return (
                            <TableCell key={header.index} align="center">{header.transformer(row[header.index])}</TableCell>
                          )
                        } else {
                          return (
                            <TableCell key={header.index} align="center">{row[header.index]}</TableCell>
                          )
                        }
                      }
                  }
                })}
              </TableRow>
            ))}
            {content.length == 0 && (
              <TableRow>
                <TableCell colSpan={headers.length} className={classes.emptyData}>
                  No triggered events.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {!disablePagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  page={pageNb}
                  onChangePage={handleChangePage}
                  rowsPerPage={pageSize}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={pageSizeOptions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CustomTable;