import {
  makeStyles,
  createStyles,
  Theme,
  TextField,
  Typography,
  Button
} from '@material-ui/core';
import { useState } from 'react';
import ProvinceService from '../../services/Province';
import TriggerDot from '../../components/TriggerDot';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex_column: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '75vh',
      overflow: 'auto'
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: theme.spacing(1, 4),
    },
    triggers: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      margin: theme.spacing(4),
      backgroundColor: theme.palette.background.default
    },
    triggers_options: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end'
    },
    triggers_options_field: {
      width: '100%',
      marginLeft: theme.spacing(2)
    },
    submit: {
      width: theme.spacing(16),
      alignSelf: 'center'
    },
    coor_options: {
      width: '45%',
    },
    single_option: {
      width: '100%',
    },
  }),
);

interface Props {
  handleClose: Function;
}

function AddProvince({ handleClose }: Props) {
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const [coordinate, setCoordinate] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const [yellow, setYellow] = useState<number>(0);
  const [orange, setOrange] = useState<number>(0);
  const [red, setRed] = useState<number>(0);

  const [coorXErrorText, setCoorXErrorText] = useState<string>('');
  const [coorYErrorText, setCoorYErrorText] = useState<string>('');

  const [path, setPath] = useState<string>('');

  const handleChangeProvinceName = (value: string) => {
    setName(value);
  }

  const handleChangeProvinceCoordinateX = (value: string) => {
    const coor = coordinate;
    coor.x = parseFloat(value);
    if (coor.x > 90 || coor.x < -90) {
      setCoorXErrorText('latitude must be between -90 and 90');
    } else if (coor.x % 0.25 !== 0) {
      setCoorXErrorText('decimals must be 0; 0.25; 0.5 or 0.75');
    } else {
      setCoorXErrorText('');
    }
    setCoordinate(coor);
  }

  const handleChangeProvinceCoordinateY = (value: string) => {
    const coor = coordinate;
    coor.y = parseFloat(value)
    if (coor.y > 180 || coor.y < -180) {
      setCoorYErrorText('longitude must be between -90 and 90');
    } else if (coor.y % 0.25 !== 0) {
      setCoorYErrorText('decimals must be 0; 0.25; 0.5 or 0.75');
    } else {
      setCoorYErrorText('');
    }
    setCoordinate(coor);
  }

  const handleChangeYellow = (value: string) => {
    setYellow(parseFloat(value));
  }
  const handleChangeOrange = (value: string) => {
    setOrange(parseFloat(value));
  }
  const handleChangeRed = (value: string) => {
    setRed(parseFloat(value));
  }

  const submit = () => {
    ProvinceService.createProvince({
      name,
      coordinate,
      yellow_trigger: yellow,
      orange_trigger: orange,
      red_trigger: red,
      path: path !== "" ? path.toUpperCase() : null
    }).then(
      res => { handleClose() },
      err => { console.log(err) }
    );
  }

  return (
    <div className={classes.flex_column}>
      <Typography variant='h5'>Add Province</Typography>
      <div className={classes.flex_row}>
        <TextField
          className={classes.single_option}
          label='Province Name'
          onChange={(e) => { handleChangeProvinceName(e.target.value) }}
        />
      </div>
      <div className={classes.flex_row}>
        <TextField
          className={classes.coor_options}
          label='Latitude coordinate'
          defaultValue={coordinate.x}
          onChange={(e) => { handleChangeProvinceCoordinateX(e.target.value) }}
          type='Number'
          InputProps={{ inputProps: { min: -90, max: 90, step: 0.25 } }}
          error={coorXErrorText.length === 0 ? false : true}
          helperText={coorXErrorText}
        />
        <TextField
          className={classes.coor_options}
          label='Longitude coordinate'
          defaultValue={coordinate.y}
          onChange={(e) => { handleChangeProvinceCoordinateY(e.target.value) }}
          type='Number'
          InputProps={{ inputProps: { min: -180, max: 180, step: 0.25 } }}
          error={coorYErrorText.length === 0 ? false : true}
          helperText={coorYErrorText}
        />
      </div>
      <div className={classes.flex_row}>
        <TextField
          className={classes.single_option}
          label='Province borders path (WKT MultiPolygon)'
          defaultValue={path}
          onChange={(e) => { setPath(e.target.value) }}
          type='Text'
          multiline={true}
          rowsMax={10}
        />
      </div>
      <div className={classes.triggers}>
        <Typography variant='h6'>Triggers</Typography>
        <div className={classes.triggers_options}>
          <TriggerDot level={0} />
          <TextField className={classes.triggers_options_field} label='Yellow' type='Number'
            onChange={(e) => { handleChangeYellow(e.target.value) }}
          />
        </div>
        <div className={classes.triggers_options}>
          <TriggerDot level={1} />
          <TextField className={classes.triggers_options_field} label='Orange' type='Number'
            onChange={(e) => { handleChangeOrange(e.target.value) }}
          />
        </div>
        <div className={classes.triggers_options}>
          <TriggerDot level={2} />
          <TextField className={classes.triggers_options_field} label='Red' type='Number'
            onChange={(e) => { handleChangeRed(e.target.value) }}
          />
        </div>
      </div>
      <Button variant='contained' color='secondary'
        className={classes.submit} onClick={submit}
      >
        Submit
      </Button>
    </div>
  );
}

export default AddProvince;