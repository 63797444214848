import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Color,
} from '@material-ui/core';
import { MultiPolygon } from 'geojson';
import { MapContainer, Pane, TileLayer } from 'react-leaflet';
import InteractiveMultiPolygon from './interactiveMultiPolygon';
import { latLng, LatLng, latLngBounds, LatLngExpression, Map } from 'leaflet';
import HomeControl from './homeControl';
import { ReactElement, useEffect, useState } from 'react';
import { multiPolygon, AllGeoJSON, centroid } from '@turf/turf';
import useDrawer from '../../hooks/drawer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      width: '100%'
    },
    mapContainer: {
      height: '100%',
      minHeight: '800px',
      width: '100%',
      '& a.MuiButton-containedSecondary': {
        color: '#F6F6F8'
      }
    }
  }),
);

interface Props {
  provinces: { id: number, name: string, multiPolygon: MultiPolygon, color: Color, popup: ReactElement }[];
  resize?: boolean;
}

function InteractivePhilippinesMap({ provinces, resize }: Props) {
  const classes = useStyles();
  const [map, setMap] = useState<Map>();
  const [center, setCenter] = useState<LatLngExpression>([12.9417921, 122.2861588]);
  const [zoom, setZoom] = useState<number>(7);
  const { drawerOpened } = useDrawer();

  useEffect(() => {
    if (map && provinces.length > 0) {
      const collection: AllGeoJSON = {
        type: 'FeatureCollection',
        features: [...provinces.filter(p => p.multiPolygon).map(p => multiPolygon(p.multiPolygon.coordinates))]
      }
      const coord = centroid(collection).geometry.coordinates;
      setCenter(new LatLng(coord[0], coord[1]));
    }
  }, [provinces, map]);

  useEffect(() => {
    map?.setView(center, zoom);
  }, [center, zoom, map])

  useEffect(() => {
    map?.invalidateSize();
  }, [resize, drawerOpened, map]);

  return (
    <Paper className={classes.paper}>
      <MapContainer
        center={center}
        zoom={zoom}
        doubleClickZoom={false}
        className={classes.mapContainer}
        whenCreated={(m) => {
          setMap(m);
        }}
        maxBounds={latLngBounds(latLng(4.0, 116.0), latLng(21.0, 128.0))}
        maxBoundsViscosity={0.8}
        maxZoom={9}
        minZoom={7}
      >
        <HomeControl center={center} zoom={zoom} />
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png"
          attribution={`${drawerOpened}`}
        />
        <Pane name='labels' style={{ zIndex: 600, pointerEvents: 'none' }}>
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png"
            attribution='©OpenStreetMap, ©CartoDB'
          />
        </Pane>
        {provinces.map((province, index) => {
          if (province.multiPolygon) {
            return <InteractiveMultiPolygon
              id={`tableLink${index}`}
              key={province.id}
              color={province.color}
              multiPolygon={province.multiPolygon}
              popup={province.popup}
              tooltip={province.name}
              isFirst={index === 0}
            />
          }
        })}
      </MapContainer>
    </Paper>
  );
}

export default InteractivePhilippinesMap;
