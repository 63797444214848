import ApiService from './Api';
import InsureeModel from '../models/Insuree';
import InsureeCreationModel from '../models/creationModels/Insuree';
import InsureeFile from '../models/InsureeFile';
import InsureeUpdateModel from '../models/updateModels/Insuree';

class Insuree {

  getAllInsurees(
    withCovers: boolean,
    withBenefits: boolean
  ):Promise<InsureeModel[]> {
    return ApiService.get(`/insurees?covers=${withCovers}&benefits=${withBenefits}`);
  }

  getAllInsureesPaginated(
    withCovers: boolean,
    withBenefits: boolean,
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ):Promise<[InsureeModel[], number]> {
    return ApiService.get(`/insurees/paginated?covers=${withCovers}&benefits=${withBenefits}&pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

  createInsuree(insuree: InsureeCreationModel):Promise<InsureeModel> {
    return ApiService.post(`/insurees/create`, insuree);
  }

  getFiles(insureeId: number): Promise<InsureeFile[]> {
    return ApiService.get(`/insurees/${insureeId}/documents`);
  }

  getFile(insureeId: number, fileId: number) {
    return ApiService.getBinary(`/insurees/${insureeId}/documents/${fileId}`);
  }

  addFile(insureeId: number, type: string, file: File) {
    return ApiService.postBinary(`/insurees/${insureeId}/documents/${type}`, file);
  }

  deleteFile(insureeId: number, fileId: number) {
    return ApiService.delete(`/insurees/${insureeId}/documents/${fileId}`);
  }

  getFileTypes() {
    return [
      {
        id: 'policy-schedule',
        label: 'Policy Schedule',
      },
      {
        id: 'application-form',
        label: 'Application Form',
      }
    ];
  }
  
  updateInsuree(insuree: InsureeUpdateModel):Promise<InsureeModel> {
    return ApiService.post(`/insurees/update`, insuree);
  }
  
  deleteInsuree(insureeId: number) {
    return ApiService.delete(`/insurees/delete/${insureeId}`);
  }
  
}

export default new Insuree();
