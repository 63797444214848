import {
  makeStyles,
  createStyles,
  Theme,
  TableRow,
  TableCell,
  FormControl,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import { useEffect, useState } from 'react';

import TriggerDot from '../../components/TriggerDot';
import UtilsService from '../../services/Utils';
import EventModel from '../../models/EventsView';
import BenefitService from '../../services/Benefit';
import { confirmAlert } from 'react-confirm-alert';
import '../modals/css/react-confirm-alert.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
    },
    link: {
      textDecoration: 'none'
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
    top: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(2)
    },
  }),
);

interface Header {
  index: keyof EventModel,
  title: string,
  special: string,
  transformer: Function
}

interface CustomTableProps {
  headers: Header[];
  index: number;
  content: EventModel;
  onChangeSelected: Function;
  onSave: Function;
  selected: boolean;
}

enum Trigger {
  yellow = 'yellow_trigger',
  orange = 'orange_trigger',
  red = 'red_trigger'
}

enum Status {
  pending = 'pending',
  accepted = 'accepted'
}

function PendingEventsRow({
  headers,
  index,
  content,
  onChangeSelected,
  onSave,
  selected
}: CustomTableProps) {
  const classes = useStyles();
  const [selectedTriggerLevel, setSelectedTriggerLevel] = useState<Trigger>(Trigger.yellow);
  const [status, setStatus] = useState<Status>(Status.pending);
  const [enabled, setEnabled] = useState<boolean>(true);

  useEffect(() => {
    switch (content.trigger) {
      case 'orange_trigger':
        setSelectedTriggerLevel(Trigger.orange);
        break;
      case 'red_trigger':
        setSelectedTriggerLevel(Trigger.red);
        break;
      default:
        setSelectedTriggerLevel(Trigger.yellow);
        break;
    }
    switch (content.status) {
      case 'accepted':
        setStatus(Status.accepted);
        setEnabled(false);
        break;
      default:
        setStatus(Status.pending);
        setEnabled(true);
        break;
    }
  }, [content]);

  const handleChangeSelected = (rowNb: number) => {
    onChangeSelected(rowNb);
  }

  const handleChangeSelectedTriggerLevel = (triggerLevel: Trigger) => {
    switch (triggerLevel) {
      case Trigger.yellow:
        onChangeSelected(0);
        break;
      case Trigger.orange:
        onChangeSelected(1);
        break;
      case Trigger.red:
        onChangeSelected(2);
        break;
      default:
        onChangeSelected(0);
        break;
    }
    setSelectedTriggerLevel(triggerLevel);
  }

  const handleChangeStatus = (status: Status) => {
    setStatus(status);
  }

  const confirmSave = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: handleSave
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  const handleSave = () => {
    BenefitService.createBenefitsWithEvent({
      date: content.date,
      precipitationId: content.precipitationId,
      provinceId: content.provinceId,
      trigger: selectedTriggerLevel
    }).then(() => {
      onSave();
    });
  }

  return (
    <TableRow
      onClick={() => { handleChangeSelected(index) }}
      hover
      selected={selected}
    >
      {headers.map((header, index) => {
        if (header.special) {
          switch (header.special) {
            case 'triggerSelection':
              return (
                <TableCell key={index} align="center">
                  <FormControl>
                    <Select
                      color="secondary"
                      value={selectedTriggerLevel}
                      autoWidth
                      onChange={(evt: any) => handleChangeSelectedTriggerLevel(evt.target.value)}
                      disabled={!enabled}
                    >
                      <MenuItem value={Trigger.yellow}><TriggerDot level={0} /></MenuItem>
                      <MenuItem value={Trigger.orange}><TriggerDot level={1} /></MenuItem>
                      <MenuItem value={Trigger.red}><TriggerDot level={2} /></MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              );
            case 'accepted':
              return (
                <TableCell key={index} align="center">
                  <FormControl>
                    <Select
                      color="secondary"
                      value={status}
                      autoWidth
                      onChange={(evt: any) => handleChangeStatus(evt.target.value)}
                      disabled={!enabled}
                    >
                      <MenuItem value={Status.pending}>{Status.pending}</MenuItem>
                      <MenuItem value={Status.accepted}>{Status.accepted}</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
              );
            case 'diff':
              return (
                <TableCell key={index} align="center">
                  {UtilsService.computePrecipitationDiff(content.value, content[selectedTriggerLevel])}
                </TableCell>
              );
            case 'save':
              return (
                <TableCell key={index} align="center">
                  <Button
                    variant='contained'
                    color='secondary'
                    disabled={!enabled || status !== Status.accepted}
                    onClick={() => { confirmSave() }}
                  >
                    Save
                  </Button>
                </TableCell>
              );
          }
        } else {
          if (header.transformer) {
            return (
              <TableCell key={index} align="center">{header.transformer(content[header.index])}</TableCell>
            )
          } else {
            return (
              <TableCell key={index} align="center">{content[header.index]}</TableCell>
            )
          }
        }
      })}
    </TableRow>
  );
}

export default PendingEventsRow;