import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const triggerColors = [
  '#FFE600',
  '#FF9900',
  '#C92519',
]

interface TriggerDotProps {
  level: number; // 0 - yellow // 1 - orange // 2 - red
}

function TriggerDot({ level }: TriggerDotProps) {
  return (
    <FiberManualRecordIcon style={{
      color: triggerColors[level],
      verticalAlign: 'middle'
    }} />
  );
}

export default TriggerDot;