import { createStyles, makeStyles, Modal } from '@material-ui/core';
import FilesListIcon from '@material-ui/icons/List';

import Page from '../components/Page';
import CustomAdminTable from '../components/admin/CustomAdminTable';
import { useEffect, useState } from 'react';
import InsureeService from '../services/Insuree';
import ProvinceService from '../services/Province';
import UtilsService from '../services/Utils';
import AddCooperative from '../components/modals/AddCooperative';
import AddProvince from '../components/modals/AddProvince';
import InsureeFiles from '../components/modals/InsureeFiles';
import UsersList from '../components/admin/UsersList';
import MirrorPanel from '../components/admin/MirrorPanel';
import UpdateProvince from '../components/modals/UpdateProvince';
import UpdateCooperative from '../components/modals/UpdateCooperative';
import CoverModel from '../models/Cover';
import { confirmAlert } from 'react-confirm-alert';
import '../components/modals/css/react-confirm-alert.css';
import { red } from '@material-ui/core/colors';
import { Geometry } from 'wkx';

const headersCoops = [
  { index: 'id', title: '#' },
  { index: 'coopName', title: 'Cooperative Name' },
  { index: 'coverage', title: 'Total Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) }
];

const headersProvinces = [
  { index: 'id', title: '#' },
  { index: 'province', title: 'Province' },
  {
    index: 'trigger', title: 'Trigger', subHeaders: [
      { index: 'yellow_trigger', title: 'Yellow', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
      { index: 'orange_trigger', title: 'Orange', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
      { index: 'red_trigger', title: 'Red', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
    ]
  }
];

interface ContentCoops {
  id: number,
  coopName: string,
  coverage: number,
  covers: CoverModel[]
};

interface ContentProvinces {
  id: number,
  province: string,
  coordinate: { x: number, y: number };
  trigger: {
    yellow_trigger: number,
    orange_trigger: number,
    red_trigger: number
  },
  path: string
};

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      '& > *': {
        width: '100%',
        height: 'fit-content'
      }
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 600,
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(2, 4),
      borderRadius: theme.spacing(1),
      maxHeight: '80vh'
    },
    error_btn: {
      backgroundColor: red[500]
    }
  }),
);

function Admin() {
  const classes = useStyles();

  const [coops, setCoops] = useState<ContentCoops[]>([]);
  const [coopsTotal, setCoopsTotal] = useState<number>(0);
  const [coopsPageNb, setCoopsPageNb] = useState<number>(0);
  const [coopsPageSize, setCoopsPageSize] = useState<number>(10);
  const [coopsNameFilter, setCoopsNameFilter] = useState<string>('');
  const [coopIdToEdit, setCoopIdToEdit] = useState<number>();
  const [coopToEdit, setCoopToEdit] = useState<ContentCoops>();

  const [provinces, setProvinces] = useState<ContentProvinces[]>([]);
  const [provincesTotal, setProvincesTotal] = useState<number>(0);
  const [provincesPageNb, setProvincesPageNb] = useState<number>(0);
  const [provincesPageSize, setProvincesPageSize] = useState<number>(10);
  const [provincesNameFilter, setProvincesNameFilter] = useState<string>('');
  const [provinceIdToEdit, setProvinceIdToEdit] = useState<number>();
  const [provinceToEdit, setProvinceToEdit] = useState<ContentProvinces>();

  const [open, setOpen] = useState(false);
  const [isCoopModalUsed, setIsCoopModalUsed] = useState(false);
  const [editFileInsureeId, setEditFileInsureeId] = useState<number | null>(null); // If not null, the modal for management of insuree's files is visible
  const [isAddModal, setIsAddModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    InsureeService.getAllInsureesPaginated(true, true, coopsPageSize, coopsPageNb, coopsNameFilter).then(res => {
      const contents = res[0].map(i => {
        const content: ContentCoops = {
          id: i.id,
          coopName: i.name,
          coverage: i.covers.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          covers: i.covers
        };
        return content;
      });
      setCoops(contents);
      setCoopsTotal(res[1]);
    },
      err => { console.log(err) }
    ).then(() => {
      ProvinceService.getAllProvincesPaginated(provincesPageSize, provincesPageNb, provincesNameFilter).then(res => {
        const contents = res[0].map(p => {
          const content: ContentProvinces = {
            id: p.id,
            province: p.name,
            coordinate: p.coordinate,
            trigger: {
              yellow_trigger: p.yellow_trigger,
              orange_trigger: p.orange_trigger,
              red_trigger: p.red_trigger
            },
            path: p.path ? Geometry.parseGeoJSON(p.path).toWkt() : ''
          };
          return content;
        });
        setProvinces(contents);
        setProvincesTotal(res[1]);
      },
        err => { console.log(err) }
      );
    });
  }, [refresh]);

  useEffect(() => {
    InsureeService.getAllInsureesPaginated(true, true, coopsPageSize, coopsPageNb, coopsNameFilter).then(res => {
      const contents = res[0].map(i => {
        const content: ContentCoops = {
          id: i.id,
          coopName: i.name,
          coverage: i.covers.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          covers: i.covers
        };
        return content;
      });
      setCoops(contents);
      setCoopsTotal(res[1]);
    },
      err => { console.log(err) }
    );
  }, [coopsPageSize, coopsPageNb, coopsNameFilter]);

  useEffect(() => {
    ProvinceService.getAllProvincesPaginated(provincesPageSize, provincesPageNb, provincesNameFilter).then(res => {
      const contents = res[0].map(p => {
        const content: ContentProvinces = {
          id: p.id,
          province: p.name,
          coordinate: p.coordinate,
          trigger: {
            yellow_trigger: p.yellow_trigger,
            orange_trigger: p.orange_trigger,
            red_trigger: p.red_trigger
          },
          path: p.path ? Geometry.parseGeoJSON(p.path).toWkt() : ''
        };
        return content;
      });
      setProvinces(contents);
      setProvincesTotal(res[1]);
    },
      err => { console.log(err) }
    );
  }, [provincesPageSize, provincesPageNb, provincesNameFilter]);

  useEffect(() => {
    setCoopToEdit(coops.find(c => c.id === coopIdToEdit));
  }, [coopIdToEdit, coops])

  useEffect(() => {
    setProvinceToEdit(provinces.find(p => p.id === provinceIdToEdit));
  }, [provinceIdToEdit, provinces])

  const body = (
    <div className={classes.modal}>
      {isAddModal && (
        <>
          {isCoopModalUsed && (
            <AddCooperative handleClose={() => { handleClose() }} />
          )}
          {!isCoopModalUsed && (
            <AddProvince handleClose={() => { handleClose() }} />
          )}
        </>
      )}
      {!isAddModal && (
        <>
          {isCoopModalUsed && (
            <UpdateCooperative handleClose={() => { handleClose() }} coopToEdit={coopToEdit} refreshCallback={() => setRefresh(!refresh)} />
          )}
          {!isCoopModalUsed && (
            <UpdateProvince handleClose={() => { handleClose() }} provinceToEdit={provinceToEdit} />
          )}
        </>
      )}
    </div>
  );

  const handleOpen = (coop: boolean, add: boolean, objectId?: number) => {
    setIsCoopModalUsed(coop);
    setIsAddModal(add);
    if (objectId && coop) {
      setCoopIdToEdit(objectId)
      setCoopToEdit(coops.find(c => c.id === objectId));
    };
    if (objectId && !coop) {
      setProvinceIdToEdit(objectId)
      setProvinceToEdit(provinces.find(p => p.id === objectId))
    };
    setOpen(true);
  };

  const handleClose = () => {
    setRefresh(!refresh);
    setOpen(false);
  };

  const handleChangePageCoop = (pageSize: number, pageNb: number) => {
    setCoopsPageNb(pageNb);
    setCoopsPageSize(pageSize);
  };

  const handleChangeRowsPerPageCoop = (pageSize: number) => {
    setCoopsPageNb(0);
    setCoopsPageSize(pageSize);
  };

  const handleChangeSearchCoop = (search: string) => {
    setCoopsNameFilter(search);
  };

  const handleDeleteCoop = (id: number) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => InsureeService.deleteInsuree(id)
          .then(res => {
            if (res.status === 'ok') {
              setRefresh(!refresh);
            } else {
              handleError(res.error);
            }
          })
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleChangePageProv = (pageSize: number, pageNb: number) => {
    setProvincesPageNb(pageNb);
    setProvincesPageSize(pageSize);
  };

  const handleChangeRowsPerPageProv = (pageSize: number) => {
    setProvincesPageNb(0);
    setProvincesPageSize(pageSize);
  };

  const handleChangeSearchProv = (search: string) => {
    setProvincesNameFilter(search);
  };

  const handleDeleteProv = (id: number) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => ProvinceService.deleteProvince(id)
          .then(res => {
            if (res.status === 'ok') {
              setRefresh(!refresh);
            } else {
              handleError(res.error);
            }
          })
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };

  const handleError = (error: string) => {
    confirmAlert({
      title: 'An error occured !',
      message: error,
      buttons: [
        {
          label: 'Ok',
          className: 'react-confirm-alert-button-error',
          onClick: () => {}
        }
      ]
    });
  };

  return (
    <Page title="Admin">
      <div className={classes.flex}>
        <CustomAdminTable
          title='Cooperatives' headers={headersCoops} content={coops}
          addFunction={() => { handleOpen(true, true) }}
          editFunction={(object: ContentCoops) => { handleOpen(true, false, object.id) }}
          removeFunction={handleDeleteCoop}
          total={coopsTotal}
          pageNb={coopsPageNb}
          pageSize={coopsPageSize}
          onChangePage={handleChangePageCoop}
          onChangeRowsPerPage={handleChangeRowsPerPageCoop}
          onChangeSearch={handleChangeSearchCoop}
          additionalActions={[{
            icon: <FilesListIcon />,
            handler: (insuree: { id: number }) => {
              setEditFileInsureeId(insuree.id);
            }
          }
          ]}
        />
        <CustomAdminTable
          title='Provinces' headers={headersProvinces} content={provinces}
          addFunction={() => { handleOpen(false, true) }}
          editFunction={(object: ContentProvinces) => { handleOpen(false, false, object.id) }}
          removeFunction={handleDeleteProv}
          total={provincesTotal}
          pageNb={provincesPageNb}
          pageSize={provincesPageSize}
          onChangePage={handleChangePageProv}
          onChangeRowsPerPage={handleChangeRowsPerPageProv}
          onChangeSearch={handleChangeSearchProv}
        />
      </div>
      <div>
        <UsersList />
      </div>
      { process.env.REACT_APP_ENV === 'prod' 
        ? ( <MirrorPanel /> )
        : (<></>)
      }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <>
        {editFileInsureeId !== null && (
          <InsureeFiles
            insureeId={editFileInsureeId}
            onClose={() => setEditFileInsureeId(null)}
          />
        )}
      </>
    </Page>
  );
}

export default Admin;