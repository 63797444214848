import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  withStyles,
  TablePagination,
  TextField,
  debounce
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import PendingEventsRow from '../admin/PendingEventsTable.row';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
    },
    link: {
      textDecoration: 'none'
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
    top: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingBottom: theme.spacing(2)
    },
  }),
);

interface CustomTableProps {
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeSearch: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
  onChangeSelected: Function;
  onSave: Function;
}

function PendingEventsTable({
  headers,
  content,
  total,
  pageSize,
  pageNb,
  onChangePage,
  onChangeRowsPerPage,
  onChangeSearch,
  disableSearch = false,
  pageSizeOptions = [5, 10, 20],
  onChangeSelected,
  onSave
}: CustomTableProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(-1);

  const chageSearch = useCallback(
    debounce((name: string) => {
      onChangeSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    chageSearch(search);
  }, [search])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
    setSelected(-1);
    onChangeSelected(-1);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setSelected(-1);
    onChangeSelected(-1);
  }

  const handleChangeSelected = (rowNb: number) => {
    setSelected(rowNb);
    onChangeSelected(rowNb);
  }

  return (
    <Paper className={classes.paper}>
      {!disableSearch && (
        <div className={classes.top}>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            color="secondary"
            onChange={handleChangeSearch}
          />
        </div>
      )}
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <StyledTableCell key={index} align="center">{header.title}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.map((row, index) => (
              <PendingEventsRow
                key={index}
                headers={headers}
                index={index}
                content={row}
                onChangeSelected={handleChangeSelected}
                onSave={onSave}
                selected={selected === index}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={pageNb}
                onChangePage={handleChangePage}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={pageSizeOptions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default PendingEventsTable;