/* eslint-disable no-undef */

//redirects the user to the login page with the right paramaters such as client_id, state, scope...
import Cookies from "universal-cookie";

const loginAuthorizationServer = (cookie: Cookies) => {

	fetch(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/login?` + new URLSearchParams(
		JSON.stringify({
		response_type: "code",
		client_id: process.env.REACT_APP_CLIENT_ID,
		redirect_uri: process.env.REACT_APP_CLIMBS_URL,
		scope: "openid+email",
		state: cookie.get("state")
	})), {
		method: "GET"
	})
	.then((response) => {
		window.location.href = `
		${process.env.REACT_APP_LOGIN_FRONTEND_URL}/login?
		response_type=code
		&client_id=${process.env.REACT_APP_CLIENT_ID}
		&redirect_uri=${process.env.REACT_APP_CLIMBS_URL}/login
		&scope=openid+email
		&state=${cookie.get("state")}
		` ;
	});
};

export default loginAuthorizationServer;