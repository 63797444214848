/* eslint-disable no-undef */

//redirects the user to the login page with the right paramaters such as client_id, state, scope...
import Api from './Api';

const ExchangeToken = async (code: string) => {
		return fetch(`${process.env.REACT_APP_AUTHORIZATION_SERVER_URL}/token`, {
			method: "POST",
			headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
			body: JSON.stringify({
				grant_type: "authorization_code",
				client_id: process.env.REACT_APP_CLIENT_ID,
				client_secret: process.env.REACT_APP_CLIENT_SECRET,
				redirect_uri: process.env.REACT_APP_CLIMBS_URL+"/login",
				code: code
			}),
			credentials: "include"
		})
		.then((response) => {
			return response.json();
		})
		.then(async (data) => {
			window.localStorage.setItem("Access", data.access_token);
			window.localStorage.setItem("Id", data.id_token);
			const token = {
				accessToken: data.access_token,
				refreshToken: data.refresh_token,
				expiration: new Date()
			};
			const user = await Api.post('/auth/validate', { token });
			return {user, token};
		})
		.catch((error) => {
			console.error("/token Post request", "Error while executing Post request", error);
			return;
		});
}

export default ExchangeToken;
