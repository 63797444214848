import { useState, useEffect } from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from '@material-ui/core';

import Insuree from '../models/Insuree';
import InsureeService from '../services/Insuree';
import useUser from '../hooks/user';

const InsureeSelector = () => {
  const { selectedInsureeId, selectInsureeId } = useUser();
  const [insurees, setInsurees] = useState<Insuree[]>([]);

  useEffect(() => {
    InsureeService
      .getAllInsurees(false, false)
      .then((insurees) => {
        setInsurees(insurees);
        if(!selectedInsureeId && insurees.length > 0) {
          selectInsureeId(insurees[0].id);
        }
      });
  }, []);

  return (
    <Box mb={3} width={300}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label" color="secondary">Cooperatives</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          color="secondary"
          value={selectedInsureeId || -1}
          autoWidth
          onChange={(evt: any) => selectInsureeId(evt.target.value)}
        >
          {insurees.map((insuree) => (
            <MenuItem key={insuree.id} value={insuree.id}>{insuree.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default InsureeSelector;
