import { AppBar, Button, createStyles, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import CompPage from '../components/Page';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = '/workers/pdf.worker.min.js';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

const useStyles = makeStyles((theme) =>
  createStyles({
    page: {
      display: 'flex',
      flexDirection: 'column',
      width: '1008px', // size of the pdf viewer
      margin: 'auto'
    },
    upper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      margin: theme.spacing(2, 0, 2, 1)
    },
    upper_left: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%'
    },
    desc: {
      padding: '0 1rem 0.5rem 0',
      '& > div': {
        paddingLeft: theme.spacing(3)
      }
    },
    file_selection: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: theme.spacing(1)
    },
    file_selection_button: {
      margin: theme.spacing(0, 1, 0, 0)
    },
    file_viewer_download: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    file_viewer_download_button: {
      marginLeft: theme.spacing(4)
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    flex_col: {
      display: 'flex',
      flexDirection: 'column',
    },
    flex_center: {
      display: 'flex',
      justifyContent: 'center'
    },
    img: {
      maxHeight: '200px',
      borderRadius: theme.spacing(2)
    },
    display: {
      position: 'relative',
      overflow: 'hidden',
      borderRadius: theme.spacing(2),
      width: 'fit-content',
      '& > $buttons': {
        opacity: '0'
      },
      '&:hover': {
        '& > $buttons': {
          opacity: '1'
        },
      }
    },
    buttons: {
      position: 'absolute',
      bottom: theme.spacing(6),
      right: `calc(50% - ${theme.spacing(8)}px)`,
      height: theme.spacing(6),
      width: theme.spacing(16),

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0 30px 40px 0 rgb(16 36 94 / 20%)',
      borderRadius: theme.spacing(0.5),
      overflow: 'hidden',
      transition: 'opacity ease-in-out 0.2s'
    },
    button: {
      height: '100%',
      width: theme.spacing(5),
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'pointer'
      }
    },
    bold: {
      fontWeight: 'bold'
    }
  }),
);

interface File {
  filePath: string,
  fileName: string
};

const files: File[] = [
  {
    filePath: '/pdfs/Maize_Recommendations_all_PH.pdf',
    fileName: 'Maize.pdf'
  },
  {
    filePath: '/pdfs/Livestock_Recommendations_PH.pdf',
    fileName: 'Livestock.pdf'
  },
  {
    filePath: '/pdfs/Cassava_Recommendations_PH.pdf',
    fileName: 'Cassava.pdf'
  },
  {
    filePath: '/pdfs/Irrigated_Rice_Recommendations_PH.pdf',
    fileName: 'Rice.pdf'
  },
];

function Recommendation() {
  const classes = useStyles();
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [file, setFile] = useState(files[0].filePath);
  const [fileName, setFileName] = useState(files[0].fileName);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const changePage = useCallback(
    (offset) => setPageNumber((prevPageNumber) => (prevPageNumber || 1) + offset),
    [],
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedFileIndex(newValue);
    setFile(files[newValue].filePath);
    setFileName(files[newValue].fileName);
    setPageNumber(1);
  };

  const previousPage = useCallback(() => changePage(-1), [changePage]);

  const nextPage = useCallback(() => changePage(1), [changePage]);

  return (
    <CompPage title={`Recommendations`}>
      <div className={classes.page}>
        <div className={classes.upper}>
          <div className={classes.upper_left}>
            <div className={classes.desc}>
              Here is advice for farmers to improve quality of crops and livestock under different farming conditions: <b>normal</b>, <b>wet</b> and <b>dry</b>.
              <br /><br />Documents that provide advice for a crop in all cycles of the plant:
              <div>• plant varieties and land preparation</div>
              <div>• weed and pest management</div>
              <div>• harvesting and storage</div>
              as well as a document that provides advice for livestock.
              <br /><br />Advice is provided by Alliance of Bioversity International and CIAT
            </div>
            <div className={classes.flex_col}>
              <div className={classes.file_viewer_download}>
                <Typography variant="h6">
                  PDF Viewer - {fileName}
                </Typography>
                <div>
                  <Button className={classes.file_viewer_download_button} variant='contained' color='primary' href={file} download>Download</Button>
                </div>
              </div>
            </div>
          </div>
          <img src="/recommendation.png" alt="recommendation" className={classes.img} />
        </div>

        <AppBar position="static" color="default">
          <Tabs
            value={selectedFileIndex}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {files.map(f => (
              <Tab key={f.fileName} className={classes.bold} label={f.fileName} />
            ))}
          </Tabs>
        </AppBar>
        <div className={classes.flex_center}>
          <div className={classes.display}>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              <Page
                pageNumber={pageNumber}
              />
            </Document>
            <div className={classes.buttons}>
              <button
                disabled={pageNumber <= 1}
                onClick={previousPage}
                type="button"
                className={classes.button}
              >
                {'<'}
              </button>
              <span>
                {`${pageNumber || (numPages ? 1 : '--')} of ${numPages || '--'}`}
              </span>
              <button
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                type="button"
                className={classes.button}
              >
                {'>'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </CompPage>
  );
}

export default Recommendation;