import {
  makeStyles,
  createStyles,
  Button,
  Paper,
  Typography,
  Box,
} from '@material-ui/core';

import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import ProvinceService from '../services/Province';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import PrecipitationModel from '../models/Precipitation';
import PrecipitationChart from '../components/PrecipitationChart';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useUser from '../hooks/user';
import { Role } from '../models/User';
import ProvinceModel from '../models/Province';
import TriggerDot from '../components/TriggerDot';
import moment from 'moment';
import Cover from '../models/Cover';
import { DatePicker } from '@material-ui/pickers';

const headers = [
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'precipitation', title: 'Precip in mm in 24h', transformer: (v: number) => UtilsService.formatPrecipitation(v) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'benefit', title: 'Payout', transformer: (v: number) => UtilsService.formatCurrency(v) },
]

interface Content {
  id: number,
  date: string,
  precipitation: number,
  trigger: string,
  benefit: number,
}

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex'
    },
    return: {
      margin: '16px'
    },
    title: {
      fontWeight: 'bold'
    },
    flex_col: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%'
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      height: '16rem',
      '& > *': {
        width: '80%'
      }
    },
    header: {
      width: '8rem'
    },
    paper: {
      padding: theme.spacing(2),
      margin: theme.spacing(2)
    },
  }),
);

function DashboardProvince() {
  const classes = useStyles();
  const { user } = useUser();
  const { provinceId, insureeId } = useParams<{ provinceId: string, insureeId: string }>();
  const [province, setProvince] = useState<ProvinceModel>();
  const [benefits, setBenefits] = useState<Content[]>([]);
  const [coverage, setCoverage] = useState<number>(0);
  const [covers, setCovers] = useState<Cover[]>([]);
  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [precipitations, setPrecipitations] = useState<PrecipitationModel[]>([]);

  const [coverageDate, setCoverageDate] = useState<moment.Moment | null>(moment());

  const initInsureeProvinceView = useCallback(() => {
    ProvinceService.getMyProvince(provinceId).then((res) => {
      setProvince(res);
      setCovers(res.covers);
      setCoverage(res.covers.filter(c => moment(c.from) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.to)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const benefits = res.covers/* .filter(c => moment(c.from) <= moment() && moment() <= moment(c.to) ) */.map(c => c.benefits).flat();
      setTotalBenefits(benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const contents = benefits.map(b => {
        const content: Content = {
          id: b.id,
          date: b.precipitation.date,
          precipitation: b.precipitation.value,
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      setBenefits(contents);
    },
      err => { console.log(err) }
    );
  }, [provinceId, coverageDate]);

  const initNoneInsureeProvinceView = useCallback(() => {
    ProvinceService.getProvinceOfInsuree(provinceId, insureeId).then((res) => {
      setProvince(res);
      setCovers(res.covers);
      setCoverage(res.covers.filter(c => moment(c.from) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.to)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const benefits = res.covers/* .filter(c => moment(c.from) <= moment() && moment() <= moment(c.to) ) */.map(c => c.benefits).flat();
      setTotalBenefits(benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
      const contents = benefits.map(b => {
        const content: Content = {
          id: b.id,
          date: b.precipitation.date,
          precipitation: b.precipitation.value,
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      setBenefits(contents);
    },
      err => { console.log(err) }
    );
  }, [provinceId, insureeId, coverageDate]);

  useEffect(() => {
    if (user.role === Role.Insuree) {
      initInsureeProvinceView();
    } else {
      initNoneInsureeProvinceView();
    }
  }, []);

  useEffect(() => {
    setCoverage(covers.filter(c => moment(c.from) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.to)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
    const benefits = covers/* .filter(c => moment(c.from) <= moment() && moment() <= moment(c.to) ) */.map(c => c.benefits).flat();
    setTotalBenefits(benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0));
  }, [covers, provinceId, insureeId, coverageDate]);

  useEffect(() => {
    PrecipitationService.getByProvince(provinceId).then(res => {
      const filteredProvinces = province ? province.covers.filter(c => moment(c.from) <= moment() && moment() <= moment(c.to)) : null;
      const startOfCoverage = filteredProvinces ? moment(filteredProvinces.reduce((pre, cur) => moment(pre.from).isSameOrBefore(cur.from) ? pre : cur).from) : null;
      const endOfCoverage = filteredProvinces ? moment(filteredProvinces.reduce((pre, cur) => moment(pre.to).isSameOrAfter(cur.to) ? pre : cur).to) : null;
      setPrecipitations(
        res
          .filter(pre => startOfCoverage ? moment(pre.date).isSameOrAfter(startOfCoverage) : true) // must be after coverage start
          .filter(pre => endOfCoverage ? moment(pre.date).isSameOrBefore(endOfCoverage) : true) // must be before coverage end
          .filter(pre => moment(pre.date).isSameOrBefore(moment())) // must be before current date
          .filter((pre, ind, arr) => moment(pre.date).isSameOrAfter(moment(arr[arr.length - 1].date).startOf('day').add(-3, 'months'))) // must be after last date - 3 months
          .sort((a, b) => moment(a.date) > moment(b.date) ? 1 : -1)
      );
    },
      err => { console.log(err) }
    );
  }, [province])

  return (
    <Page title={`Dashboard for ${province?.name}`}>
      <>
        {user.role !== Role.Insuree &&
          <Box mb={3} width={300}>
            <DatePicker
              label='Coverage Date' color='secondary' format='DD MMM YYYY'
              value={coverageDate} onChange={setCoverageDate}
            />
          </Box>
        }
      </>
      <div className={classes.flex}>
        <div style={{ width: '100%' }}>
          <div className={classes.flex}>
            <SingleDataCard title={'Coverage'} content={`${UtilsService.formatCurrency(coverage)}`} />
            <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
          </div>
          <>
            {province && (
              <Paper className={classes.paper}>
                <div className={classes.flex_row}>
                  <div className={classes.header}>
                    <Typography variant='h6' className={classes.title}>
                      Triggers
                    </Typography>
                    <div><TriggerDot level={0} /> {UtilsService.formatPrecipitation(province.yellow_trigger)}</div>
                    <div><TriggerDot level={1} /> {UtilsService.formatPrecipitation(province.orange_trigger)}</div>
                    <div><TriggerDot level={2} /> {UtilsService.formatPrecipitation(province.red_trigger)}</div>
                  </div>
                  <PrecipitationChart
                    precipitations={precipitations}
                    red_trigger={province.red_trigger}
                    orange_trigger={province.orange_trigger}
                    yellow_trigger={province.yellow_trigger}
                    min_date={(precipitations.length > 0 ? moment(precipitations[0].date) : moment()).startOf('day').valueOf()}
                    max_date={(precipitations.length > 0 ? moment(precipitations[precipitations.length - 1].date) : moment()).startOf('day').valueOf()}
                  />
                </div>
              </Paper>
            )}
          </>
          <CustomTable
            headers={headers}
            content={benefits}
            total={0}
            pageNb={0}
            pageSize={0}
            onChangePage={() => { }}
            onChangeRowsPerPage={() => { }}
            disableSearch={true}
            onChangeSearch={() => { }}
          />
        </div>
      </div>
      <div className={classes.flex}>
        <Button
          variant="contained"
          color="secondary"
          href="/dashboard"
          className={classes.return}
        >
          Return
        </Button>
      </div>
    </Page>
  );
}

export default DashboardProvince;
