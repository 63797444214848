import ApiService from './Api';

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  expiration: Date;
};

class Authenticate {

  login(email: string, password: string):Promise<LoginResponse> {
    return ApiService
      .post(
        '/auth/login',
        { email, password }
      )
      .then(({ accessToken, refreshToken, expiration }) => ({
        accessToken,
        refreshToken,
        expiration: new Date(expiration),
      }));
  }

  logout() {
    ApiService.removeTokens();
    return ApiService
      .post(
        '/auth/logout',
        {}
      );
  }
  
}

export default new Authenticate();
