import ApiService from './Api';
import LogModel from '../models/Log';

class Log {
  getLogsPaginated(
    pageSize: number,
    pageNb: number,
    messageFilter: string,
    levelFilter: string
  ):Promise<[LogModel[], number]> {
    return ApiService.get(`/logs?pageSize=${pageSize}&pageNb=${pageNb}&messageFilter=${messageFilter}&levelFilter=${levelFilter}`);
  }  
}

export default new Log();
