import {
  CssBaseline,
  ThemeProvider,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Router from './components/Router';
import { UserContextProvider } from './components/UserContext';
import { DrawerContextProvider } from './components/DrawerContext';
import User from './models/User';
import theme from './theme';
import MomentUtils from '@date-io/moment';

interface InitialState {
  user: User | null;
}

function App({ user }: InitialState) {
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div className="app" style={{ height: '100%' }}>
            <UserContextProvider initialUser={user}>
              <DrawerContextProvider initialState={true}>
                <Router />
              </DrawerContextProvider>
            </UserContextProvider>
          </div>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
