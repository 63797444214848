import {
  Card,
  CardContent,
  makeStyles,
  createStyles,
  Theme,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      padding: theme.spacing(1.2, 4),
      margin: theme.spacing(0, 2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: '3px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    title: {
      textAlign: 'center',
      fontSize: '1em',
      fontWeight: 'bold',
      fontStyle: 'italic',
      color: theme.palette.text.secondary
    },
    content: {
      textAlign: 'center',
      fontSize: '1.5em',
    },
  }),
);

interface SingleDataCardProps {
  title: string;
  content: string;
}

function SingleDataCard({ title, content }: SingleDataCardProps) {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.content}>
        {content}
      </Typography>
    </Box>
  );
}

export default SingleDataCard;