import { useMap } from "react-leaflet";
import L, { Control, LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";

interface Props {
  center: LatLngExpression;
  zoom: number;
}

const HomeControl = ({ center, zoom }: Props) => {
  const map = useMap();
  const [homeControl, setHomeControl] = useState<Control | null>(null);

  useEffect(() => {
    if (homeControl != null) {
      homeControl.remove();
    }
    const HomeControl: Control = L.control.attribution({ position: 'topleft' });

    HomeControl.onAdd = () => {
      const button = L.DomUtil.create("button", "leaflet-bar leaflet-control leaflet-control-zoom");
      button.style.width = '34px';
      button.style.height = '34px';
      button.style.display = 'flex';
      button.style.justifyContent = 'center';
      button.style.alignItems = 'center';
      button.style.backgroundColor = '#FFFFFF';
      button.style.cursor = 'pointer';
      const img = document.createElement('img');
      img.src = '/icons/home.png';
      button.appendChild(img);
      button.onclick = () => map.setView(center, zoom);
      return button;
    };

    setHomeControl(HomeControl.addTo(map));
  }, [center, zoom]);
  return null;
};

export default HomeControl;
