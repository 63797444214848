import { Button, createStyles, makeStyles } from '@material-ui/core';

import Page from '../components/Page';
import CustomTable from '../components/CustomTable';
import { useEffect, useState } from 'react';
import ActivityService from '../services/Activity';
import UtilsService from '../services/Utils';
import '../components/modals/css/react-confirm-alert.css';

const headers = [
  { index: 'timestamp', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'timestamp', title: 'Time', transformer: (d: string) => UtilsService.formatTime(d) },
  { index: 'email', title: 'User email' },
  { index: 'type', title: 'User type' },
  { index: 'coopName', title: 'Coop name', transformer: (d: string) => d === null ? '-' : d },
  { index: 'coopProvinces', title: 'Coop province names', transformer: (d: string) => d === null ? '-' : d },
];

interface Content {
  id: number,
  timestamp: string,
  email: string,
  type: string,
  coopName: string,
  coopProvinces: string
};

const JSONtoCSV = (arr: any[], columns: string[], delimiter = ',') => [
  `sep=${delimiter}`,
  columns.join(delimiter),
  ...arr.map(obj =>
    columns.reduce((acc, key) => `${acc}${!acc.length ? '' : delimiter}"${!obj[key] ? '' : obj[key]}"`, '')
  )
].join('\n');

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      width: 'fit-content',
      margin: theme.spacing(2)
    }
  }),
);

function Activity() {
  const classes = useStyles();

  const [activity, setActivity] = useState<Content[]>([]);
  const [Total, setTotal] = useState<number>(0);
  const [PageNb, setPageNb] = useState<number>(0);
  const [PageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    ActivityService.get(PageSize, PageNb).then(res => {
      const contents = res[0];
      setActivity(contents);
      setTotal(res[1]);
    },
      err => { console.log(err) }
    );
  }, [PageSize, PageNb]);

  const handleChangePage = (pageSize: number, pageNb: number) => {
    setPageNb(pageNb);
    setPageSize(pageSize);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setPageNb(0);
    setPageSize(pageSize);
  };

  return (
    <Page title="Activity">
      <div className={classes.flex}>
        <CustomTable
          headers={headers} content={activity}
          total={Total}
          pageNb={PageNb}
          pageSize={PageSize}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          disableSearch
        />
        <Button
          className={classes.button}
          variant='contained'
          color='secondary'
          href={`data:text/json;charset=utf-8,${encodeURIComponent(
            JSONtoCSV(activity.map(a => ({
              Date: UtilsService.formatDate(a.timestamp),
              Time: UtilsService.formatTime(a.timestamp),
              "User email": a.email,
              "User type": a.type,
              "Coop name": a.coopName,
              "Coop province names": a.coopProvinces ? a.coopProvinces.replaceAll(', ', '";"') : null
            })), ['Date', 'Time', 'User email', 'User type', 'Coop name', 'Coop province names'], ';')
          )}`}
          download="activity.csv"
        >
          Export to CSV
        </Button>
      </div>
    </Page>
  );
}

export default Activity;