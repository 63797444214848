import {
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Chip,
  Typography
} from '@material-ui/core';
import {
  NavLink as RouterLink,
  useHistory
} from 'react-router-dom';
import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import OverviewIcon from '@material-ui/icons/HomeOutlined';
import ProductIcon from '@material-ui/icons/ShoppingCartOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import TriggeredEventsIcon from '@material-ui/icons/DateRangeOutlined';
import ContactIcon from '@material-ui/icons/PersonOutlineOutlined';
import AdminIcon from '@material-ui/icons/SettingsOutlined';
import UserProfileIcon from '@material-ui/icons/Tune';
import EcoIcon from '@material-ui/icons/Eco';
import EventIcon from '@material-ui/icons/Event';
import CloudIcon from '@material-ui/icons/WbCloudy';
import HelpIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/ArrowBackIosOutlined';
import OpenIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { Role } from '../models/User';
import useUser from '../hooks/user';
import AuthService from '../services/Authenticate';
import _envConfig from '../configurations/env.json';
import { Env } from '../configurations/env';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';
import { useCallback, useEffect, useState } from 'react';
import useDrawer from '../hooks/drawer';

const menu = [
  {
    name: 'Overview',
    id: 'linkToOverview',
    icon: OverviewIcon,
    link: '/overview',
    exact: 'true',
    roles: [Role.Admin, Role.SuperUser],
  },
  {
    name: 'Dashboard',
    id: 'linkToDashboard',
    icon: DashboardIcon,
    link: '/dashboard',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Product',
    id: 'linkToProduct',
    icon: ProductIcon,
    link: '/product',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Historical Rainfall',
    id: 'linkToHistorical',
    icon: CloudIcon,
    link: '/historical-rainfall',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Triggered Events',
    id: 'linkToTriggered',
    icon: TriggeredEventsIcon,
    link: '/triggered-events',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Recommendations',
    id: 'linkToRecommendations',
    icon: EcoIcon,
    link: '/recommendations',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Contact',
    id: 'linkToContact',
    icon: ContactIcon,
    link: '/contact',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
  {
    name: 'Admin',
    id: 'linkToAdmin',
    icon: AdminIcon,
    link: '/admin',
    roles: [Role.Admin],
  },
  {
    name: 'Logs',
    id: 'linkToLogs',
    icon: AdminIcon,
    link: '/adminLogs',
    roles: [Role.Admin],
  },
  {
    name: 'Activity',
    id: 'linkToActivity',
    icon: AdminIcon,
    link: '/activity',
    roles: [Role.Admin],
  },
  {
    name: 'Pending Events',
    id: 'linkToPending',
    icon: EventIcon,
    link: '/pendingEvents',
    roles: [Role.Admin],
  },
  {
    name: 'My Profile',
    id: 'linkToProfile',
    icon: UserProfileIcon,
    link: '/me',
    roles: [Role.Admin, Role.SuperUser, Role.Insuree],
  },
];

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      flexShrink: 0,
      position: 'relative'
    },
    drawerPaper: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
      border: 0,
      paddingTop: theme.spacing(1)
    },
    drawerOpen: {
      width: drawerWidth,
      overflowX: 'hidden',
    },
    drawerClosed: {
      overflowX: 'hidden',
      width: theme.spacing(9),
    },
    img: {
      width: '50%',
      alignSelf: 'center',
    },
    list: {
      paddingLeft: theme.spacing(1),
      '& .MuiListItemIcon-root': {
        minWidth: theme.spacing(5)
      }
    },
    listClose: {
      marginTop: theme.spacing(8)
    },
    link: {
      borderTopLeftRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
      '& svg': {
        fill: theme.palette.background.default,
      },
      '&:hover': {
        backgroundColor: '#0000003A',
      }
    },
    activeLink: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.dark,
      borderTopLeftRadius: theme.spacing(3),
      borderBottomLeftRadius: theme.spacing(3),
      '& svg': {
        fill: theme.palette.primary.dark,
      },
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      }
    },
    logout: {
      margin: 'auto 16px 16px 16px',
      backgroundColor: '#ffffff4a',
      color: theme.palette.background.default,
      '&:hover': {
        backgroundColor: '#9a9a9a4a',
      }
    },
    chip: {
      width: 'fit-content',
      alignSelf: 'center',
      margin: theme.spacing(1),
      backgroundColor: '#ffffff4a',
      color: theme.palette.background.default,
    },
    chip_env: {
      width: 'fit-content',
      alignSelf: 'center',
      margin: theme.spacing(1),
      backgroundColor: '#ffffff4a',
      color: theme.palette.background.default,
    },
    dev: { backgroundColor: '#593CB8' },
    staging: { backgroundColor: '#FF3D3D' },
    mirror: { backgroundColor: '#B02AB0' },
    prod: {},
    powerdBy: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2),
      paddingTop: '0',
      width: '100%',
      '& a': {
        width: '50%',
        '& img': {
          width: '100%'
        }
      }
    },
    help: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(5),
      cursor: 'pointer'
    },
    helpClosed: {
      position: 'absolute',
      right: theme.spacing(3),
      top: theme.spacing(5),
      cursor: 'pointer'
    },
    burger: {
      position: 'absolute',
      right: theme.spacing(1),
      cursor: 'pointer'
    },
    burgerClosed: {
      position: 'absolute',
      right: theme.spacing(3),
      cursor: 'pointer'
    }
  }),
);

const filterMenuWithUserRole = (role: Role) =>
  menu.filter(({ roles }) => roles.indexOf(role) >= 0);

function Drawer() {
  const classes = useStyles();
  const { user } = useUser();
  const env: Env = (process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev') as Env;
  const envConfig = _envConfig.themes.filter(t => t.envs.includes(env))[0];

  const history = useHistory();
  const {drawerOpened, setDrawerOpened} = useDrawer();

  const [run, setRun] = useState(window.localStorage.getItem('joyrideFinished') !== 'true');
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState<Step[]>([
    {
      target: 'body',
      placement: 'center',
      content: 'Great to see you here! Let me show you the most important things on this platform. Let\'s get started!',
      showSkipButton: false,
      hideCloseButton: true,
      event: 'hover'
    },
    {
      target: '#helpIcon',
      spotlightPadding: 10,
      content: 'You can skip this tour anytime and come back to it using this button.',
      hideCloseButton: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#linkToProduct',
      spotlightPadding: 10,
      content: 'This is where you can find all the information about your policy.',
      hideCloseButton: true,
      spotlightClicks: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#linkToDashboard',
      spotlightPadding: 10,
      content: 'This is where you can find your coverage and payouts.',
      hideCloseButton: true,
      spotlightClicks: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#interactiveMap',
      spotlightPadding: 10,
      content: 'On this map you can click on a province to see the rainfall since the start of your policy and the level at which you will get a payout.',
      hideCloseButton: true,
      event: 'hover',
      offset: 0
    },
    {
      target: '#closeIcon',
      spotlightPadding: 10,
      content: 'Click on the arrow to hide the menu bar.',
      hideCloseButton: true,
      event: 'hover',
      offset: 0
    },
  ]);

  const handleJoyrideCallback = useCallback((data: CallBackProps) => {
    const { action, index, status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const ongoingEvents: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const beforeNextStep: string[] = [EVENTS.STEP_BEFORE];
    if (ongoingEvents.includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if (finishedStatuses.includes(status)) {
      setRun(false);
      window.localStorage.setItem('joyrideFinished', 'true');
    } else if (beforeNextStep.includes(type)) {
      if (index === 0) {
        setDrawerOpened(true);
      } else if (index === 1) {
        window.scrollTo(0, 0);
      } else if (index === 2) {
        history.push('/product');
      } else if (index === 3) {
        history.push('/dashboard');
      } else if (index === 5) {
        window.scrollTo(0, 0);
      }
    }
  }, []);

  const handleHelp = useCallback(() => {
    setRun(true);
    if (stepIndex >= steps.length) {
      setStepIndex(0);
    }
  }, [steps, stepIndex]);

  useEffect(() => {
    if (run) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto"
    }
  }, [run])

  return (
    <MuiDrawer
      className={`${classes.drawer} ${drawerOpened ? classes.drawerOpen : classes.drawerClosed}`}
      variant="permanent"
      classes={{
        paper: `${classes.drawerPaper} ${drawerOpened ? classes.drawerOpen : classes.drawerClosed}`,
      }}
      anchor="left"
      open={drawerOpened}
    >
      <Joyride
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        showProgress={true}
        showSkipButton={true}
        disableCloseOnEsc={true}
        disableOverlayClose={true}
        continuous={true}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
            primaryColor: envConfig.colors.secondary.main
          }
        }}
      />
      {drawerOpened && (
        <CloseIcon id='closeIcon' className={classes.burger} onClick={() => setDrawerOpened(false)} />
      )}
      {!drawerOpened && (
        <OpenIcon id='openIcon' className={classes.burgerClosed} onClick={() => setDrawerOpened(true)} />
      )}
      <HelpIcon id='helpIcon' className={drawerOpened ? classes.help : classes.helpClosed} onClick={handleHelp} />
      {drawerOpened && (
        <>
          <img src={envConfig.logo_on_dark} alt='CLIMBS logo' className={classes.img} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {env !== 'prod' && (
              <Chip label={env} className={classes.chip + " " + classes[env]} />
            )}
            <Chip label={user.role} className={classes.chip} />
          </div>
        </>
      )}
      <List className={`${classes.list} ${drawerOpened ? null : classes.listClose}`}>
        {filterMenuWithUserRole(user.role).map(({ name, id, link, icon: Icon, exact }, index) => (
          <ListItem
            button
            id={`${id}`}
            key={index}
            component={RouterLink}
            to={link}
            exact={exact ? true : false}
            className={classes.link}
            activeClassName={classes.activeLink}>
            <ListItemIcon><Icon /></ListItemIcon>
            {drawerOpened && (
              <ListItemText primary={name} />
            )}
          </ListItem>
        ))}
      </List>
      {drawerOpened && (
        <>
          <Button
            className={classes.logout}
            variant='contained'
            onClick={() => {
              return AuthService.logout();
            }}
            href='\'
          >
            Logout
          </Button>
          <div className={classes.powerdBy}>
            <Typography>Powered by</Typography>
            <a href='https://ibisa.network/'>
              <img src='/logo_ibisa_white.png' alt='IBISA' />
            </a>
          </div>
        </>
      )}
    </MuiDrawer>
  );
};

export default Drawer;
