import { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
  IconButton,
  withStyles,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
} from '@material-ui/core';
import ActiveIcon from '@material-ui/icons/CheckCircle';
import InactiveIcon from '@material-ui/icons/NotInterested';
import RequestRenewPasswordIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';

import UserService from '../../services/User';
import User from '../../models/User';
import CreateUser from '../modals/CreateUser';

const getRoleName = (role: string) => {
  switch(role) {
    case 'admin': return 'Administrator';
    case 'super-user': return 'CLIMBS';
    case 'insuree': return 'User';
  }
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    border: 'solid 1px #e0e0e0',
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    paper: {
      margin: '16px',
      padding: '16px'
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(2)
    },
  })
);

const ConfirmChangeUserStatus = ({
  user,
  inactive,
  onClose,
  onConfirm,
}: {
  user: User;
  inactive: boolean;
  onConfirm: Function;
  onClose: Function;
}) => {
  return (
    <Dialog open={true}>
      <DialogTitle>
        { inactive
          ? `Are you sure to disable access for user ${user.email}?`
          : `Are you sure to enable access for user ${user.email}?`
        }
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm()}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const ConfirmRequestRenewUserPassword = ({
  onClose,
  onConfirm,
}: {
  onConfirm: Function;
  onClose: Function;
}) => {
  return (
    <Dialog open={true}>
      <DialogTitle>
        Are you sure to request for this user's password to be renewed?
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm()}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
};

const UsersList = ({}) => {
  const classes = useStyles();
  const [users, setUsers] = useState({ count: 0, values: [] as User[]});
  const [pagination, setPagination] = useState({
    nbResults: 10,
    page: 0,
  });
  const [loading, setLoading] = useState(false);
  const [changeRequestRenewUser, setChangeRequestRenewUser] = useState<User | null>(null);
  const [changeUserStatus, setChangeUserStatus] = useState<{user: User, inactive: boolean} | null>(null);
  const [isAddUser, setAddUser] = useState(false);

  const loadUsers = () => {
    setLoading(true);
    UserService
      .getAllUsers(pagination.nbResults, pagination.page)
      .then((users) => {
        setUsers(users);
        setLoading(false)
      });
  };

  useEffect(loadUsers, [pagination]);

  const handleChangePage = (_event: any, newPage: number) => {
    setPagination({
      ...pagination,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagination({
      ...pagination,
      nbResults: +event.target.value,
    });
  };

  const confirmChangeUserStatus = (user: User, inactive: boolean) => {
    setLoading(true);
    UserService
      .changeUserStatus(user.id, !inactive)
      .then(() => {
        setChangeUserStatus(null);
        loadUsers();
      });
  };

  const confirmRequestRenewUserPassword = (user: User) => {
    setLoading(true);
    UserService
      .requestRenewUserPassword(user.email)
      .then(() => {
        setChangeRequestRenewUser(null);
        loadUsers();
      });
  };

  return (
    <Paper className={classes.paper}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          Users
        </Typography>
        <Button
          startIcon={<AddIcon />}
          variant='contained' color='secondary'
          onClick={() => setAddUser(true)}
        >
          Add
        </Button>
      </Box>
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                Email
              </StyledTableCell>
              <StyledTableCell>
                Name
              </StyledTableCell>
              <StyledTableCell>
                Role
              </StyledTableCell>
              <StyledTableCell>
                Active
              </StyledTableCell>
              <StyledTableCell>
                Cooperative
              </StyledTableCell>
              <StyledTableCell align="center">
                Actions
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { users.values.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  { user.email }
                </TableCell>
                <TableCell>
                  { user.name }
                </TableCell>
                <TableCell>
                  { getRoleName(user.role) }
                </TableCell>
                <TableCell>
                  { user.active
                    ? <ActiveIcon />
                    : <InactiveIcon />
                  }
                </TableCell>
                <TableCell>
                  { user.insuree?.name || 'N/A' }
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    title="Request renew password"
                    onClick={() => setChangeRequestRenewUser(user)}
                  >
                    <RequestRenewPasswordIcon />
                  </IconButton>
                  { user.active && (
                    <IconButton
                      title="Switch to INACTIVE"
                      onClick={() => setChangeUserStatus({ user, inactive: true })}
                    >
                      <InactiveIcon />
                    </IconButton>
                  )}
                  { user.active === false && (
                    <IconButton
                      title="Switch to ACTIVE"
                      onClick={() => setChangeUserStatus({ user, inactive: false })}
                    >
                      <ActiveIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={users.count}
                page={pagination.page}
                rowsPerPage={pagination.nbResults}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      { changeUserStatus !== null && (
        <ConfirmChangeUserStatus
          user={changeUserStatus.user}
          inactive={changeUserStatus.inactive}
          onConfirm={() => confirmChangeUserStatus(changeUserStatus.user, changeUserStatus.inactive)}
          onClose={() => setChangeUserStatus(null)}
        />
      )}
      { changeRequestRenewUser !== null && (
        <ConfirmRequestRenewUserPassword
          onConfirm={() => confirmRequestRenewUserPassword(changeRequestRenewUser)}
          onClose={() => setChangeRequestRenewUser(null)}
        />
      )}
      { isAddUser && (
        <CreateUser
          onClose={(reload) => {
            setAddUser(false);
            if(reload) loadUsers();
          }}
        />
      )}
    </Paper>
  );
};

export default UsersList;