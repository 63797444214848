import {
  makeStyles,
  createStyles,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import { useEffect, useState } from 'react';
import InsureeService from '../services/Insuree';
import BenefitService from '../services/Benefit';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import ProvinceService from '../services/Province';
import reinsuranceCapacityConfig from '../configurations/reinsuranceCapacity.json'

const headersCoops = [
  { index: 'coopName', title: 'Cooperative Name' },
  { index: 'totalCoverage', title: 'Total Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'lastTrigger', title: 'Last Trigger Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'totalBenefits', title: 'Total Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
];

const headersTriggers = [
  { index: 'province', title: 'Province' },
  { index: 'coopName', title: 'Cooperative' },
  { index: 'date', title: 'Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'trigger', title: 'Trigger' },
  { index: 'benefit', title: 'Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
];

const headersReinsurance = [
  { index: 'name', title: 'Province' },
  { index: 'capacity', title: 'Reinsurance Capacity', transformer: (v: number) => UtilsService.formatCurrency(v) },
  {
    index: 'used', title: 'Total used (to date) per province', subHeaders: [
      { index: 'coverage', title: 'Total', transformer: (v: number) => UtilsService.formatCurrency(v) },
      { index: 'percentage', title: '% of reinsurance capacity', transformer: (v: number) => UtilsService.formatPercentage(v) },
    ]
  },
  { index: 'balance', title: 'Balance', transformer: (v: number) => UtilsService.formatCurrency(v) },
];

interface ContentCoops {
  id: number,
  coopName: string,
  totalCoverage: number,
  lastTrigger: string,
  totalBenefits: number
};

interface ContentTriggers {
  id: number,
  province: string,
  coopName: string,
  date: string,
  trigger: string,
  benefit: number
};

interface ContentReinsurance {
  id: number,
  name: string,
  capacity: number,
  used: {
    coverage: number,
    percentage: number
  },
  balance: number,
  isTotal?: boolean
};

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex'
    },
    select: {
      margin: '16px'
    },
    paper: {
      margin: '16px',
    },
    link: {
      textDecoration: 'none'
    },
    box: {
      '& > div': {
        margin: 0,
        padding: 0,
        boxShadow: 'none'
      }
    },
    bold: {
      fontWeight: 'bold'
    }
  }),
);

function Overview() {
  const classes = useStyles();
  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [lastUpdate, setLastUpdate] = useState<string>('-');
  const [tabValue, setTabValue] = useState(0);

  const [coops, setCoops] = useState<ContentCoops[]>([]);
  const [coopsTotal, setCoopsTotal] = useState<number>(0);
  const [coopsPageNb, setCoopsPageNb] = useState<number>(0);
  const [coopsPageSize, setCoopsPageSize] = useState<number>(10);
  const [coopsNameFilter, setCoopsNameFilter] = useState<string>('');

  const [triggers, setTriggers] = useState<ContentTriggers[]>([]);
  const [triggersTotal, setTriggersTotal] = useState<number>(0);
  const [triggersPageNb, setTriggersPageNb] = useState<number>(0);
  const [triggersPageSize, setTriggersPageSize] = useState<number>(10);

  const [reinsuranceCapacity, setReinsuranceCapacity] = useState<ContentReinsurance[]>([]);

  useEffect(() => {
    BenefitService.getTotalBenefitsValue().then(res => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) });
    PrecipitationService.getLastUpdate()
      .then(res => {
        setLastUpdate(res.lastUpdate ? res.lastUpdate : '-');
      },
        err => { console.log(err) });
    ProvinceService.getReinsuranceCapacity().then((res) => {
      const totalCoverage: number = res.reduce((prev, curr) => prev + (parseFloat(curr.coverage) ? parseFloat(curr.coverage) : 0), 0);
      const totalCapacity: number = res.length * reinsuranceCapacityConfig.global_capacity;
      const total: ContentReinsurance = {
        id: 0,
        name: '',
        capacity: res.length * reinsuranceCapacityConfig.global_capacity,
        used: {
          coverage: totalCoverage,
          percentage: 0,
        },
        balance: totalCapacity - totalCoverage,
        isTotal: true
      };
      const values = res.map(r => ({
        id: r.Province_id,
        name: r.Province_name,
        capacity: reinsuranceCapacityConfig.global_capacity,
        used: {
          coverage: parseFloat(r.coverage),
          percentage: (parseFloat(r.coverage) / reinsuranceCapacityConfig.global_capacity) * 100
        },
        balance: r.coverage ? reinsuranceCapacityConfig.global_capacity - parseFloat(r.coverage) : reinsuranceCapacityConfig.global_capacity
      }));
      values.push(total);
      setReinsuranceCapacity(values);
    });
  }, []);

  useEffect(() => {
    InsureeService.getAllInsureesPaginated(true, true, coopsPageSize, coopsPageNb, coopsNameFilter).then(res => {
      const contents = res[0].map(i => {
        const benefits = i.covers.map(c => c.benefits).flat();
        const content = {
          id: i.id,
          coopName: i.name,
          totalCoverage: i.covers.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          lastTrigger: benefits.length > 0 ? benefits.reduce((acc, curr) => acc.date > curr.date ? acc : curr).date : '-',
          totalBenefits: benefits.length > 0 ? benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0) : 0
        };
        return content;
      });
      setCoops(contents);
      setCoopsTotal(res[1]);
    },
      err => { console.log(err) }
    );
  }, [coopsPageSize, coopsPageNb, coopsNameFilter]);

  useEffect(() => {
    BenefitService.getAllBenefits(true, true, true, triggersPageNb, triggersPageSize).then(res => {
      const contents = res[0].map(b => {
        const content = {
          id: b.id,
          province: b.cover.province.name,
          coopName: b.cover.insuree.name,
          date: b.date,
          trigger: b.trigger,
          benefit: parseFloat(b.amount)
        };
        return content;
      });
      contents.sort((a, b) => a.date < b.date ? 1 : -1);
      setTriggers(contents);
      setTriggersTotal(res[1]);
    },
      err => { console.log(err) }
    );
  }, [triggersPageSize, triggersPageNb]);

  const handleChangePageCoop = (pageSize: number, pageNb: number) => {
    setCoopsPageNb(pageNb);
    setCoopsPageSize(pageSize);
  };

  const handleChangeRowsPerPageCoop = (pageSize: number) => {
    setCoopsPageNb(0);
    setCoopsPageSize(pageSize);
  };

  const handleChangeSearchCoop = (search: string) => {
    setCoopsNameFilter(search);
  };

  const handleChangePageTrigger = (pageSize: number, pageNb: number) => {
    setTriggersPageNb(pageNb);
    setTriggersPageSize(pageSize);
  };

  const handleChangeRowsPerPageTrigger = (pageSize: number) => {
    setTriggersPageNb(0);
    setTriggersPageSize(pageSize);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Page title="Overview">
      <div className={classes.flex}>
        <SingleDataCard title={'Number of Cooperatives'} content={coopsTotal.toString()} />
        <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
        <SingleDataCard title={'Last Update'} content={UtilsService.formatDate(lastUpdate)} />
      </div>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab className={classes.bold} label='Covered Cooperatives' {...a11yProps(0)} />
            <Tab className={classes.bold} label='Triggered Events' {...a11yProps(1)} />
            <Tab className={classes.bold} label='Reinsurance Capacity' {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <Box p={3} hidden={tabValue !== 0} className={classes.box}>
          <CustomTable
            headers={headersCoops}
            content={coops}
            total={coopsTotal}
            pageNb={coopsPageNb}
            pageSize={coopsPageSize}
            onChangePage={handleChangePageCoop}
            onChangeRowsPerPage={handleChangeRowsPerPageCoop}
            onChangeSearch={handleChangeSearchCoop}
          />
        </Box>
        <Box p={3} hidden={tabValue !== 1} className={classes.box}>
          <CustomTable
            headers={headersTriggers}
            content={triggers}
            total={triggersTotal}
            pageNb={triggersPageNb}
            pageSize={triggersPageSize}
            onChangePage={handleChangePageTrigger}
            onChangeRowsPerPage={handleChangeRowsPerPageTrigger}
            disableSearch={true}
            onChangeSearch={() => { }}
          />
        </Box>
        <Box p={3} hidden={tabValue !== 2} className={classes.box}>
          <CustomTable
            headers={headersReinsurance}
            content={reinsuranceCapacity}
            disablePagination
            disableSearch
          />
        </Box>
      </Paper>
    </Page>
  );
}

export default Overview;