import {
  makeStyles,
  createStyles,
  Theme,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Box
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import ProvinceService from '../../services/Province';
import CoverService from '../../services/Cover';
import InsureeService from '../../services/Insuree';
import UtilsService from '../../services/Utils';
import ProvinceModel from '../../models/Province';
import ProvinceCoverageFormUpdate from './utils/ProvinceCoverageFormUpdate';
import CoverModel from '../../models/Cover';
import payoutConfig from '../../configurations/payouts.json';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex_column: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '75vh',
      overflow: 'auto'
    },
    flex_column_space: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(0, 4),
    },
    flex_column_add: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(4),
      '& > *:not(:first-of-type)': {
        marginTop: theme.spacing(2)
      }
    },
    totalCoverage: {
      alignSelf: 'center',
      margin: theme.spacing(4, 0, 0),
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.main,
      padding: theme.spacing(1, 2)
    },
    flex_row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    provinces: {
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
      margin: theme.spacing(4),
      backgroundColor: theme.palette.background.default
    },
    submit: {
      width: theme.spacing(16),
      alignSelf: 'center'
    }
  }),
);

interface ContentCoops {
  id: number,
  coopName: string,
  coverage: number,
  covers: CoverModel[]
};

interface Props {
  handleClose: Function;
  coopToEdit: ContentCoops | undefined;
  refreshCallback: Function;
}

function UpdateCooperative({ handleClose, coopToEdit, refreshCallback }: Props) {
  const classes = useStyles();
  const [provinces, setProvinces] = useState<ProvinceModel[]>([]);
  const [provincesToSelect, setProvincesToSelect] = useState<ProvinceModel[]>([]);
  const [selectedProvinceId, setselectedProvinceId] = useState<number>();
  const [selectedProvinceCoverage, setSelectedProvinceCoverage] = useState<number>(0);
  const [selectedStartDate, setSelectedStartDate] = useState<moment.Moment | null>(moment());
  const [selectedEndDate, setSelectedEndDate] = useState<moment.Moment | null>(moment().add(1, 'year'));

  const [totalCoverage, setTotalCoverage] = useState<string>('0$');
  const [name, setName] = useState<string>(coopToEdit ? coopToEdit.coopName : '');

  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    ProvinceService.getAllProvinces().then(
      res => { setProvinces(res) },
      err => { console.log(err) }
    );
  }, []);

  useEffect(() => {
    refreshCallback();
  }, [refresh]);

  useEffect(() => {
    if (coopToEdit !== undefined && coopToEdit.covers.length > 0) {
      setProvincesToSelect(provinces);
      setTotalCoverage(UtilsService.formatCurrency(coopToEdit.covers.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0)));
    } else {
      setProvincesToSelect(provinces);
    }
  }, [provinces, coopToEdit]);

  const handleChangeCoopName = (value: string) => {
    setName(value);
  }

  const handleUpdateProvince = (coverId: number, amount: number, from: moment.Moment, to: moment.Moment) => {
    CoverService.updateCover({ id: coverId, amount, from: from.toDate(), to: to.toDate() }).then(() => { setRefresh(!refresh) });
  }

  const handleDeleteProvince = (coverId: number) => {
    CoverService.deleteCover(coverId).then(() => { setRefresh(!refresh) });
  }

  const handleAddProvice = (province: number | undefined, amount: number | undefined) => {
    if (province !== undefined && amount !== undefined) {
      const cover: any = {
        insuree: coopToEdit?.id,
        province,
        amount,
        from: (selectedStartDate ? selectedStartDate : moment()).toDate(),
        to: (selectedEndDate ? selectedEndDate : moment().add(1, 'year')).toDate(),
        red_payout: payoutConfig.red_payout,
        orange_payout: payoutConfig.orange_payout,
        yellow_payout: payoutConfig.yellow_payout,
      };
      CoverService.createCover(cover).then(() => { setRefresh(!refresh) });
    }
  }

  const handleUpdateCoopName = () => {
    if (coopToEdit !== undefined) {
      InsureeService.updateInsuree({ id: coopToEdit.id, update: { name } }).then(() => { setRefresh(!refresh) });
    }
  }

  const handleChangeSelectedProvinceCoverage = (value: string) => {
    setSelectedProvinceCoverage(parseFloat(value));
  }

  return (
    <div className={classes.flex_column}>
      <Typography variant='h5'>Update Cooperative</Typography>
      <div className={classes.flex_column_space}>
        <div className={classes.flex_row}>
          <TextField label='Cooperative Name' defaultValue={name} onChange={(e) => { handleChangeCoopName(e.target.value) }} />
          <Button variant='contained' color='secondary'
            className={classes.submit} onClick={handleUpdateCoopName}
          >
            Update
          </Button>
        </div>
        <Typography className={classes.totalCoverage}>Total coverage : {totalCoverage}</Typography>
      </div>
      <div className={classes.provinces}>
        <Typography variant='h6'>Provinces</Typography>

        {coopToEdit?.covers.map(c => (
          <ProvinceCoverageFormUpdate
            key={c.province.id}
            provinceName={c.province.name}
            coverId={c.id}
            handleUpdate={handleUpdateProvince}
            handleDelete={handleDeleteProvince}
            amount={parseFloat(c.amount)}
            from={moment(c.from)}
            to={moment(c.to)}
          />
        ))}


        <Box className={classes.flex_column_add}>
          <Box className={classes.flex_row}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" color="secondary">Add province</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                color="secondary"
                value={selectedProvinceId || -1}
                autoWidth
                onChange={(evt: any) => setselectedProvinceId(evt.target.value)}
              >
                {provincesToSelect.map((p) => (
                  <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.flex_row}>
            <TextField label='Coverage'
              style={{ width: '100%' }}
              type='Number'
              defaultValue={0}
              onChange={(e) => { handleChangeSelectedProvinceCoverage(e.target.value) }}
            />
          </Box>
          <Box className={classes.flex_row}>
            <DatePicker
              label='Start of coverage' color='secondary' format='DD MMM YYYY'
              value={selectedStartDate} onChange={setSelectedStartDate}
            />
            <DatePicker
              label='End of coverage' color='secondary' format='DD MMM YYYY'
              value={selectedEndDate} onChange={setSelectedEndDate}
            />
          </Box>
          <Box className={classes.flex_row} style={{justifyContent: 'center'}}>
            <Button variant='contained' color='secondary'
              className={classes.submit}
              disabled={selectedProvinceId === undefined}
              onClick={() => { handleAddProvice(selectedProvinceId, selectedProvinceCoverage) }}
            >
              Add
            </Button>
          </Box>
        </Box>

      </div>
    </div>
  );
}

export default UpdateCooperative;