import { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  CircularProgress,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/AddBox';
import DownloadIcon from '@material-ui/icons/OpenInNew';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

import InsureeFile from '../../models/InsureeFile';
import InsureeService from '../../services/Insuree';

interface InsureeFilesProps {
  onClose: () => void;
  insureeId: number;
}

const InsureeFiles = ({ insureeId, onClose }: InsureeFilesProps) => {
  const allFileTypes = InsureeService.getFileTypes();
  const [fileType, setFileType] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<InsureeFile[]>([]);
  const [addFileAnchor, setAddFileAnchor] = useState<HTMLElement | null>(null);
  const fileRef = useRef<HTMLInputElement>(null);

  const getFileTypeLabel = (id: string) => allFileTypes.find(({ id: tId }) => tId === id)?.label || id;

  const loadFiles = () => {
    setLoading(true);
    InsureeService
      .getFiles(insureeId)
      .then((files) => {
        setFiles(files);
        setLoading(false);
      });
  };

  const openMenu = (evt: any) => {
    setAddFileAnchor(evt.currentTarget);
  };

  const handleMenuClick = (id: string) => {
    setFileType(id);
    if(fileRef && fileRef.current) {
      fileRef.current.click();
    }
  };

  const handleFileChanged = (event: any) => {
    handleCloseMenu();
    const [file] = event.target.files;
    if (fileType !== null) {
      setLoading(true);
      InsureeService
        .addFile(insureeId, fileType, file)
        .then(() => {
          // Reset input file
          if(fileRef.current)
            fileRef.current.value = '';
        })
        .then(loadFiles);
    }
  };

  const handleCloseMenu = () => {
    setAddFileAnchor(null);
  }

  const openFile = async (file: InsureeFile) => {
    const fileData = await InsureeService.getFile(insureeId, file.id);
    const blob = new Blob([ fileData ], {
      type: "application/pdf"
    });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
  };

  const handleDelete = (file: InsureeFile) => {
    setLoading(true);
    InsureeService
      .deleteFile(insureeId, file.id)
      .then(loadFiles);
  };

  useEffect(loadFiles, [insureeId]);

  return (
    <Dialog
      open
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>Cooperative's files</DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>File name</TableCell>
                <TableCell>Modification date</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { files.map((file) => (
                <TableRow key={file.id}>
                  <TableCell>{getFileTypeLabel(file.type)}</TableCell>
                  <TableCell>{file.filename}</TableCell>
                  <TableCell>{moment(file.lastUpdateDate).format("DD/MM/YYYYY HH:mm:ss")}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => openFile(file)}>
                      <DownloadIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt={3}>
          <Button
            onClick={openMenu}
            startIcon={<AddIcon  />}
            variant="contained"
            color="primary"
          >
              Add a file
          </Button>
          <Menu
            anchorEl={addFileAnchor}
            keepMounted
            open={Boolean(addFileAnchor)}
            onClose={handleCloseMenu}
          >
            { allFileTypes.map(({ id, label }) => (
              <MenuItem
                key={id}
                onClick={() => handleMenuClick(id)}
              >
                { label }
              </MenuItem>
            ))}
          </Menu>
          <input
            type="file"
            ref={fileRef}
            style={{ display: 'none' }}
            accept="application/pdf"
            onChange={handleFileChanged}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        { loading && (
          <CircularProgress size={30} />
        )}
        <Button onClick={onClose}  disabled={loading}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsureeFiles;
