import {
  makeStyles,
  createStyles,
  Theme,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  withStyles,
  TablePagination,
  TextField,
  debounce,
  Chip
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import {
  NavLink as RouterLink,
} from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(2)
    },
    link: {
      textDecoration: 'none'
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: theme.spacing(2)
    },
    left: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    chip: {
      width: 'fit-content',
      alignSelf: 'center',
      margin: theme.spacing(1),
      color: theme.palette.background.paper,
      '&.error': {
        backgroundColor: '#f50057',
      },
      '&.warn': {
        backgroundColor: '#fbc02d',
      },
      '&.info': {
        backgroundColor: '#757575',
      },
      '&.debug': {
        backgroundColor: '#4caf50',
      },
      '&.inactive': {
        opacity: '0.5'
      }
    },
  }),
);

interface CustomTableProps {
  headers: any[];
  content: any[];
  total: number;
  pageSize: number;
  pageNb: number;
  onChangePage: Function;
  onChangeRowsPerPage: Function;
  onChangeSearch: Function;
  onChangeLogLevel: Function;
  disableSearch?: boolean;
  pageSizeOptions?: number[];
}

function CustomLogsTable({ headers, content, total, pageSize, pageNb, onChangePage, onChangeRowsPerPage, onChangeSearch, onChangeLogLevel, disableSearch = false, pageSizeOptions = [10, 25, 50, 100] }: CustomTableProps) {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [levels, setLevels] = useState(['debug', 'info', 'warning', 'error']);

  const changeSearch = useCallback(
    debounce((name: string) => {
      onChangeSearch(name);
    }, 200),
    []
  );

  useEffect(() => {
    changeSearch(search);
  }, [search])

  useEffect(() => {
    onChangeLogLevel(levels.toString());
  }, [levels])

  const handleChangePage = (event: unknown, newPage: number) => {
    onChangePage(pageSize, newPage);
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeRowsPerPage(+event.target.value);
  }

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }

  const handleChangeLevel = (lvl: string) => {
    if (levels.includes(lvl)) {
      setLevels(levels.filter(l => l !== lvl));
    } else {
      setLevels(levels.concat(lvl));
    }
  }

  return (
    <Paper className={classes.paper}>
      {!disableSearch && (
        <div className={classes.top}>
          <div className={classes.left}>
            <Chip label="debug" className={`${classes.chip} debug ${levels.includes('debug') ? '' : 'inactive'}`}
              onClick={() => handleChangeLevel('debug')} />
            <Chip label="info" className={`${classes.chip} info ${levels.includes('info') ? '' : 'inactive'}`}
              onClick={() => handleChangeLevel('info')} />
            <Chip label="warning" className={`${classes.chip} warn ${levels.includes('warning') ? '' : 'inactive'}`}
              onClick={() => handleChangeLevel('warning')} />
            <Chip label="error" className={`${classes.chip} error ${levels.includes('error') ? '' : 'inactive'}`}
              onClick={() => handleChangeLevel('error')} />
          </div>
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            color="secondary"
            onChange={handleChangeSearch}
          />
        </div>
      )}
      <TableContainer className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell key={header.index} align="center">{header.title}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {content.map((row, index) => (
              <TableRow key={index}>
                {headers.map((header) => {
                  if (header.index === 'level') {
                    return (
                      <TableCell key={header.index} align="center">
                        <Chip label={row[header.index]} className={`${classes.chip} ${row[header.index]}`} />
                      </TableCell>
                    )
                  } else {
                    if (header.isLink) {
                      return (
                        <TableCell key={header.index} align="center">
                          <RouterLink to={row[header.index].link} className={classes.link}>
                            {row[header.index].value}
                          </RouterLink>
                        </TableCell>
                      )
                    } else {
                      if (header.transformer) {
                        return (
                          <TableCell key={header.index} align="center">{header.transformer(row[header.index])}</TableCell>
                        )
                      } else {
                        return (
                          <TableCell key={header.index} align="center">{row[header.index]}</TableCell>
                        )
                      }
                    }
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={pageNb}
                onChangePage={handleChangePage}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={pageSizeOptions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CustomLogsTable;