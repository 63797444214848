import {
  Card,
  CardHeader,
  Avatar,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useUser from '../hooks/user';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    userTag: {
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
    },
    header: {
      flexDirection: 'row-reverse',
      '& .MuiCardHeader-avatar': {
        marginRight: 0,
        marginLeft: '16px'
      },
      '& .MuiCardHeader-content': {
        textAlign: 'end'
      }
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.background.default
    },
  }),
);

function UserTag() {
  const { user } = useUser();
  const classes = useStyles();
  const { push } = useHistory();
  return (
    <Card className={classes.userTag} onClick={() => push('/me')}>
      <CardHeader
        className={classes.header}
        avatar={
          <Avatar className={classes.avatar}>
            {user.name.charAt(0).toUpperCase()}
          </Avatar>
        }
        title={'Welcome, ' + user.name}
        subheader={user.email}
      />
    </Card>
  );
}

export default UserTag;