import ApiService from './Api';
import ProvinceModel from '../models/Province';
import ProvinceCreationModel from '../models/creationModels/Province';
import ProvinceUpdateModel from '../models/updateModels/Province';
import ProvinceAreaModel from '../models/Province-area';

class Province {

  getAllProvinces(): Promise<ProvinceModel[]> {
    return ApiService.get(`/provinces/`);
  }

  getAllProvincesPaginated(
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ): Promise<[ProvinceModel[], number]> {
    return ApiService.get(`/provinces/paginated?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

  getReinsuranceCapacity(): Promise<{Province_id: number, Province_name: string, coverage: string}[]> {
    return ApiService.get(`/provinces/reinsuranceCapacity`);
  }

  getProvincesOfInsuree(
    id: string,
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ): Promise<[ProvinceModel[], number]> {
    return ApiService.get(`/provinces/ofInsuree/${id}?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

  getProvinceOfInsuree(
    provinceId: string,
    insureeId: string,
  ): Promise<ProvinceModel> {
    return ApiService.get(`/provinces/${provinceId}/ofInsuree/${insureeId}`);
  }

  getMyProvinces(
    pageSize: number,
    pageNb: number,
    nameFilter: string
  ): Promise<[ProvinceModel[], number]> {
    return ApiService.get(`/provinces/me?pageSize=${pageSize}&pageNb=${pageNb}&nameFilter=${nameFilter}`);
  }

  getMyProvince(id: string): Promise<ProvinceModel> {
    return ApiService.get(`/provinces/me/${id}`);
  }

  createProvince(province: ProvinceCreationModel): Promise<ProvinceModel> {
    return ApiService.post(`/provinces/create`, province);
  }

  updateProvince(province: ProvinceUpdateModel): Promise<ProvinceModel> {
    return ApiService.post(`/provinces/update`, province);
  }
  
  deleteProvince(provinceId: number) {
    return ApiService.delete(`/provinces/delete/${provinceId}`);
  }

  getProvinceAreasOfInsuree(id: string): Promise<ProvinceAreaModel[]> {
    return ApiService.get(`/provinces/areas/ofInsuree/${id}`);
  }

  getMyProvinceAreas(): Promise<ProvinceAreaModel[]> {
    return ApiService.get(`/provinces/areas/me`);
  }

}

export default new Province();
