import { useState, useEffect } from 'react';
import {
  Typography,
  Paper,
  makeStyles,
  withStyles,
  createStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import PdfIcon from '@material-ui/icons/PictureAsPdf';

import InsureeSelector from '../components/InsureeSelector';
import Page from '../components/Page';
import TriggerDot from '../components/TriggerDot';
import useUser from '../hooks/user';
import { Role } from '../models/User';
import InsureeFile from '../models/InsureeFile';
import InsureeService from '../services/Insuree';
import payoutConfig from '../configurations/payouts.json';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.black,
    fontWeight: 'bold'
  },
}))(TableCell);

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    title: {
      fontWeight: 'bold'
    },
    section: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      width: '2.4rem'
    },
    p: {
      margin: theme.spacing(2)
    },
    table: {
      border: `solid 1px ${theme.palette.grey[300]}`,
      borderRadius: theme.spacing(0.5),
    },
  }),
);

function Product() {
  const allFileTypes = InsureeService.getFileTypes();
  const [files, setFiles] = useState<InsureeFile[]>([]);
  const { user, selectedInsureeId } = useUser();
  const classes = useStyles();

  useEffect(() => {
    if (selectedInsureeId !== null) {
      InsureeService.getFiles(selectedInsureeId).then(setFiles);
    }
  }, [selectedInsureeId]);

  const getFileTypeLabel = (id: string) => allFileTypes.find(({ id: tId }) => tId === id)?.label || id;

  const openFile = async (file: InsureeFile) => {
    const fileData = await InsureeService.getFile(selectedInsureeId!, file.id);
    const blob = new Blob([fileData], {
      type: "application/pdf"
    });
    const objectUrl = URL.createObjectURL(blob);
    window.open(objectUrl);
  };

  return (
    <Page title="Product">
      <>
        {user.role !== Role.Insuree && (
          <InsureeSelector />
        )}
        <Paper className={classes.paper}>
          <Typography variant='h6' className={classes.title}>
            Product Specifications
          </Typography>
          <div className={classes.section}>
            <Typography className={classes.p}>
              This protection is designed to protect the loan portfolio of farmers in your cooperative.
            </Typography>
          </div>
          <div className={classes.section}>
            <img src='/icons/umbrella.png' className={classes.icon} />
            <Typography className={classes.p}>
              Your protection is valid to 1st July 2022 and it protects the loan portfolio against excessive rainfall.
            </Typography>
          </div>
          <div className={classes.section}>
            <img src='/icons/philippines.png' className={classes.icon} />
            <Typography className={classes.p}>
              Protection is subdivided by province. In every province precipitation is measured and summarized for a one day period.
            </Typography>
          </div>
          <div className={classes.section}>
            <img src='/icons/time.png' className={classes.icon} />
            <Typography className={classes.p}>
              Precipitation data will be updated with 7 days delay.
            </Typography>
          </div>
          <div className={classes.section}>
            <img src='/icons/rain.png' className={classes.icon} />
            <Typography className={classes.p}>
              Every province has three precipitation level triggers (yellow, orange and red) with a percentage of payouts (proportional to coverage for this province) paid when the value is exceeded (see the table below). To see specific trigger values for a province, go to the Dashboard page and click on the province name.
            </Typography>
          </div>

          <TableContainer className={classes.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Trigger</StyledTableCell>
                  <StyledTableCell align="center">Return Period</StyledTableCell>
                  <StyledTableCell align="center">Payout</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    <TriggerDot level={0} />
                    Yellow
                  </TableCell>
                  <TableCell align="center">10 years</TableCell>
                  <TableCell align="center">{`${payoutConfig.yellow_payout}%`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <TriggerDot level={1} />
                    Orange
                  </TableCell>
                  <TableCell align="center">15 years</TableCell>
                  <TableCell align="center">{`${payoutConfig.orange_payout}%`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    <TriggerDot level={2} />
                    Red
                  </TableCell>
                  <TableCell align="center">20 years</TableCell>
                  <TableCell align="center">{`${payoutConfig.red_payout}%`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <div className={classes.section}>
            <img src='/icons/research.png' className={classes.icon} />
            <Typography className={classes.p}>
              For example, cooperative has 10 000 000 ₱ of coverage in Leyte and one day it was raining excessively – precipitation value reached the orange trigger (10 %). Then, the cooperative is entitled to receive 10 000 000 x 10 % = 1 000 000 ₱
            </Typography>
          </div>
          <div className={classes.section}>
            <Typography className={classes.p}>
              Multiple triggers can be activated per year, with a maximum payout over the year of 50 %.
            </Typography>
          </div>

          <Typography variant='h6' className={classes.title}>
            Claim Process
          </Typography>
          <Typography className={classes.p}>
            • An email notification will be received by the coop on their coop’s email and in the coop’s dashboard “Triggered Events”.
          </Typography>
          <Typography className={classes.p}>
            • An email of final approval and calculation of payout will be received by the coop on their coop’s email and in the coop’s dashboard “Triggered Events”.
          </Typography>
          <Typography className={classes.p}>
            • CLIMBS will release the payout within 3 to 5 working days.
          </Typography>

          <Box mt={3}>
            <Typography variant='h6' className={classes.title}>
              Downloadable documents
            </Typography>
            <List>
              {files.map((file) => (
                <ListItem key={file.id} button onClick={() => openFile(file)}>
                  <ListItemIcon><PdfIcon style={{ color: 'rgb(201, 37, 25)' }} /></ListItemIcon>
                  <ListItemText primary={getFileTypeLabel(file.type)} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      </>
    </Page>
  );
}

export default Product;