import {
  makeStyles,
  createStyles,
  Color,
  Paper,
  AppBar,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';

import Page from '../components/Page';
import SingleDataCard from '../components/SingleDataCard';
import CustomTable from '../components/CustomTable';
import ProvinceService from '../services/Province';
import InsureeService from '../services/Insuree';
import CoverService from '../services/Cover';
import BenefitService from '../services/Benefit';
import PrecipitationService from '../services/Precipitation';
import UtilsService from '../services/Utils';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import useUser from '../hooks/user';
import { Role } from '../models/User';
import InsureeSelector from '../components/InsureeSelector';
import InteractivePhilippinesMap from '../components/InteractiveMap/InteractivePhilippinesMap';
import { MultiPolygon } from 'geojson';
import { green, grey, orange, red, yellow } from '@material-ui/core/colors';
import DashboardProvincePopup from '../components/modals/DashboardProvincePopup';
import { Coverage } from '../models/Province-area';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const headers = [
  { index: 'name', title: 'Name', isLink: true },
  { index: 'coverage', title: 'Coverage', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'lastTrigger', title: 'Last Trigger' },
  { index: 'lastTriggerDate', title: 'Last Trigger Date', transformer: (d: string) => UtilsService.formatDate(d) },
  { index: 'lastBenefit', title: 'Last Payout', transformer: (v: number) => UtilsService.formatCurrency(v) },
  { index: 'totalBenefit', title: 'Total Payout', transformer: (v: number) => UtilsService.formatCurrency(v) }
];

interface Content {
  id: number,
  name: { value: string, link: string },
  coverage: number,
  lastTrigger: string,
  lastTriggerDate: string,
  lastBenefit: number,
  totalBenefit: number
};

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      width: '100%',
      '& > *': {
        width: '100%'
      }
    },
    mapContainer: {
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,
      '& > *': {
        width: '100%',
        height: '100%',
        margin: 0,
        padding: 0,
      },
    },
    select: {
      margin: '16px'
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(2, 4),
      borderRadius: theme.spacing(1),
      maxWidth: '70vw',
      minWidth: '850px',
      maxHeight: '80vh',
      overflow: 'auto'
    },
    paper: {
      margin: '16px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    },
    box: {
      '& > div': {
        margin: 0,
        padding: 0,
        boxShadow: 'none'
      }
    },
    bold: {
      fontWeight: 'bold'
    }
  }),
);

function Dashboard() {
  const classes = useStyles();
  const { user, selectedInsureeId } = useUser();
  const [provinces, setProvinces] = useState<Content[]>([]);
  const [provincesPageNb, setProvincesPageNb] = useState<number>(0);
  const [provincesPageSize, setProvincesPageSize] = useState<number>(20);
  const [provincesTotal, setProvincesTotal] = useState<number>(0);
  const [provincesNameFilter, setProvincesNameFilter] = useState<string>('');

  const [provinceAreas, setProvinceAreas] = useState<{ id: number, name: string, multiPolygon: MultiPolygon, color: Color, popup: ReactElement }[]>([]);

  const [lastUpdate, setLastUpdate] = useState<string>('-');
  const [coverage, setCoverage] = useState<number>(0);
  const [totalBenefits, setTotalBenefits] = useState<number>(0);
  const [tabValue, setTabValue] = useState(0);

  const [selectedProvinceId, setSelectedProvinceId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);

  const [resizeMap, setResizeMap] = useState(false);

  const [coverageDate, setCoverageDate] = useState<moment.Moment | null>(moment());

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (id: number) => {
    setSelectedProvinceId(id);
    setOpen(true);
  };

  const initInsureeView = useCallback(() => {
    ProvinceService.getMyProvinces(provincesPageSize, provincesPageNb, provincesNameFilter).then((res) => {
      const contents = res[0].map(p => {
        const lastTriggers = p.covers
          .filter(c => c.benefits.length > 0)
          .map(c => c.benefits.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr));
        const lastTrigger = lastTriggers.length > 0
          ? lastTriggers.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr)
          : null;
        const content: Content = {
          id: p.id,
          name: { value: p.name, link: `/dashboard/${p.id}` },
          coverage: p.covers.filter(c => moment(c.from) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.to)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          lastTrigger: lastTrigger ? lastTrigger.trigger : '-',
          lastTriggerDate: lastTrigger ? lastTrigger.date : '-',
          lastBenefit: lastTrigger ? parseFloat(lastTrigger.amount) : 0,
          totalBenefit: p.covers.map(c => c.benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0)).reduce((acc, curr) => acc + curr)
        };
        return content;
      });
      setProvinces(contents);
      setProvincesTotal(res[1]);
      setResizeMap(!resizeMap);
    },
      err => { console.log(err) }
    );
    CoverService.getMyTotalCoverage((coverageDate || moment())).then((res) => {
      setCoverage(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
    BenefitService.getMyTotalBenefitsValue().then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
    ProvinceService.getMyProvinceAreas().then(res => {
      const areas = res.map(a => ({
        id: a.id,
        multiPolygon: a.path,
        color: a.coverage === Coverage.noTrigger ? green
          : a.coverage === Coverage.yellowTrigger ? yellow
            : a.coverage === Coverage.orangeTrigger ? orange
              : a.coverage === Coverage.redTrigger ? red
                : grey,
        popup: <DashboardProvincePopup provinceId={a.provinceId.toString()} />,
        name: a.provinceName
      }));
      setProvinceAreas([...areas]);
    });
  }, [provincesPageSize, provincesPageNb, provincesNameFilter, coverageDate]);

  const initNoneInsureeView = useCallback((id: number) => {
    ProvinceService.getProvincesOfInsuree(id.toString(), provincesPageSize, provincesPageNb, provincesNameFilter).then((res) => {
      const contents = res[0].map(p => {
        const lastTriggers = p.covers
          .filter(c => c.benefits.length > 0)
          .map(c => c.benefits.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr));
        const lastTrigger = lastTriggers.length > 0
          ? lastTriggers.reduce((acc, curr) => acc.createdAt > curr.createdAt ? acc : curr)
          : null;
        const content: Content = {
          id: p.id,
          name: { value: p.name, link: `/dashboard/${p.id}/${id}` },
          coverage: p.covers.filter(c => moment(c.from) <= (coverageDate || moment()) && (coverageDate || moment()) <= moment(c.to)).reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0),
          lastTrigger: lastTrigger ? lastTrigger.trigger : '-',
          lastTriggerDate: lastTrigger ? lastTrigger.date : '-',
          lastBenefit: lastTrigger ? parseFloat(lastTrigger.amount) : 0,
          totalBenefit: p.covers.map(c => c.benefits.reduce((acc: number, curr) => acc + parseFloat(curr.amount), 0)).reduce((acc, curr) => acc + curr)
        };
        return content;
      });
      setProvinces(contents);
      setProvincesTotal(res[1]);
      setResizeMap(!resizeMap);
    },
      err => { console.log(err) }
    );
    CoverService.getTotalCoverageOfInsuree(id.toString(), (coverageDate || moment())).then((res) => {
      setCoverage(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
    BenefitService.getTotalBenefitsValueOfInsuree(id.toString()).then((res) => {
      setTotalBenefits(res.sum ? res.sum : 0);
    },
      err => { console.log(err) }
    );
    ProvinceService.getProvinceAreasOfInsuree(id.toString()).then((res: { id: number, provinceName: string, provinceId: number, path: MultiPolygon, coverage: string }[]) => {
      const areas = res.map(a => ({
        id: a.id,
        multiPolygon: a.path,
        color: a.coverage === Coverage.noTrigger ? green
          : a.coverage === Coverage.yellowTrigger ? yellow
            : a.coverage === Coverage.orangeTrigger ? orange
              : a.coverage === Coverage.redTrigger ? red
                : grey,
        popup: <DashboardProvincePopup insureeId={selectedInsureeId?.toString()} provinceId={a.provinceId.toString()} />,
        name: a.provinceName
      }));
      setProvinceAreas([...areas]);
    });
  }, [provincesPageSize, provincesPageNb, provincesNameFilter, coverageDate, selectedInsureeId]);

  useEffect(() => {
    if (selectedInsureeId) {
      if (user.role === Role.Insuree) {
        initInsureeView();
      } else {
        InsureeService.getAllInsurees(true, true)
          .then(res => {
            initNoneInsureeView(selectedInsureeId);
          },
            err => { console.log(err) });
      }
      PrecipitationService.getLastUpdate()
        .then(res => {
          setLastUpdate(res.lastUpdate);
        },
          err => { console.log(err) });
    }
  }, [provincesPageNb, provincesPageSize, provincesNameFilter, selectedInsureeId, coverageDate]);

  useEffect(() => {
    if (selectedInsureeId) {
      if (user.role !== Role.Insuree) {
        initNoneInsureeView(selectedInsureeId);
      }
    }
  }, [selectedInsureeId, coverageDate]);

  useEffect(() => {
    if (user.role !== Role.Insuree) {
      if (selectedInsureeId) {
        CoverService.getTotalCoverageOfInsuree(selectedInsureeId.toString(), (coverageDate || moment())).then((res) => {
          setCoverage(res.sum ? res.sum : 0);
        },
          err => { console.log(err) }
        );
      }
    } else {
      CoverService.getMyTotalCoverage((coverageDate || moment())).then((res) => {
        setCoverage(res.sum ? res.sum : 0);
      },
        err => { console.log(err) }
      );
    }
  }, [selectedInsureeId, coverageDate])

  const handleChangePageProv = (pageSize: number, pageNb: number) => {
    setProvincesPageNb(pageNb);
    setProvincesPageSize(pageSize);
  };

  const handleChangeRowsPerPageProv = (pageSize: number) => {
    setProvincesPageNb(0);
    setProvincesPageSize(pageSize);
  };

  const handleChangeSearchProv = (search: string) => {
    setProvincesNameFilter(search);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setResizeMap(!resizeMap);
  };

  const a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Page title={`Dashboard`}>
      <Box>
        {user.role !== Role.Insuree &&
          <>
            <InsureeSelector />
            <Box mb={3} width={300}>
              <DatePicker
                label='Coverage Date' color='secondary' format='DD MMM YYYY'
                value={coverageDate} onChange={setCoverageDate}
              />
            </Box>
          </>
        }
      </Box>
      <Box className={classes.flex}>
        <SingleDataCard title={'Coverage'} content={`${UtilsService.formatCurrency(coverage)}`} />
        <SingleDataCard title={'Total Payouts'} content={`${UtilsService.formatCurrency(totalBenefits)}`} />
      </Box>
      <Paper className={classes.paper}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab className={classes.bold} label='Dashboard - new' {...a11yProps(0)} />
            <Tab className={classes.bold} label='Dashboard - old' {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <Box p={3} hidden={tabValue !== 0} className={`${classes.box} ${classes.mapContainer}`} id="interactiveMap">
          <InteractivePhilippinesMap provinces={provinceAreas} resize={resizeMap} />
        </Box>
        <Box p={3} hidden={tabValue !== 1} className={classes.box}>
          <CustomTable
            headers={headers}
            content={provinces}
            total={provincesTotal}
            pageNb={provincesPageNb}
            pageSize={provincesPageSize}
            onChangePage={handleChangePageProv}
            onChangeRowsPerPage={handleChangeRowsPerPageProv}
            onChangeSearch={handleChangeSearchProv}
            pageSizeOptions={[10, 20]}
          />
        </Box>
      </Paper>
    </Page>
  );
}

export default Dashboard;