import currency from "../configurations/currency.json";
import moment from 'moment';

class Utils {

  formatCurrency(amount: number): string {
    return amount ? Number(amount).toLocaleString('en-US', { minimumFractionDigits: 2 }) + currency.unit : '-';
  }

  formatPrecipitation(amount: number): string {
    return Number(amount).toLocaleString('en-US', { minimumFractionDigits: 3 }) + " mm";
  }

  formatDate(date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.format('DD MMM YYYY');
    } else {
      return '-';
    }
  }

  formatTime(date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.local().format('hh:mm A') + ' UTC';
    } else {
      return '-';
    }
  }

  formatTimestamp(date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      return d.format('DD MMM YYYY, H:mm:ss:SSS');
    } else {
      return '-';
    }
  }

  formatPercentage(amount: number): string {
    return amount ? amount.toLocaleString('en-US', { maximumFractionDigits: 0 }) + " %" : '-';
  }

  computePrecipitationDiff(value: number, trigger: number): string {
    return this.formatPercentage(((value - trigger) / value) * 100);
  }

  getXdaysBefore(daysBefore: number, date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      d.add(-daysBefore, 'day');
      return d.format('YYYY-MM-DD');
    } else {
      return '-';
    }
  }
  getXdaysAfter(daysAfter: number, date: string): string {
    const d = moment(date);
    if (d.isValid()) {
      d.add(daysAfter, 'day');
      return d.format('YYYY-MM-DD');
    } else {
      return '-';
    }
  }

}

export default new Utils();