import { useContext } from 'react';

import UserContext from '../components/UserContext';

function useUser() {
  const {
    user,
    selectedInsureeId,
    selectInsureeId,
  } = useContext(UserContext);

  return {
    user: user!,
    selectedInsureeId,
    selectInsureeId,
  };
}

export default useUser;
