import { MultiPolygon } from "geojson";

export enum Coverage {
  noCoverage = 'no_coverage',
  noTrigger = 'no_trigger',
  yellowTrigger = 'yellow_trigger',
  orangeTrigger = 'orange_trigger',
  redTrigger = 'red_trigger'
};

export default interface ProvinceArea {
  id: number;
  provinceId: number;
  provinceName: string;
  path: MultiPolygon;
  coverage: Coverage;
};